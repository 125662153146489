import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Client} from '../model/client.model';
import {Constants} from '../env/constants';
import {HttpService} from './http.service';
import {SearchFilter} from '../model/search-filter.model';
import {SearchResults} from '../model/search-results.model';
import {ClientCredentials} from '../model/client-credentials.model';

@Injectable({
	providedIn: 'root'
})
export class ClientService {

	constructor(private http: HttpService) {
	}

	public static getClientUI(client: Client): any {
		const res = {
			logo: '',
			backgroundColor: '',
			textColor: ''
		};

		if (client) {
			res.logo = client.logo;
			res.backgroundColor = client.headerBackgroundColor ? client.headerBackgroundColor : '#3f51b5';
			res.textColor = client.headerTextColor ? client.headerTextColor : 'white';
		}

		return res;
	}

	public getClientByClientId(clientId: string): Observable<Client> {
		return this.http.get(Constants.HMI.GET_CLIENT, {clientId: clientId}).pipe(
			map(response => {
				if (response.valid) {
					return new Client(response.payload);
				} else {
					throw response.message;
				}
			})
		);
	}

	public getClient(id: number): Observable<Client> {
		return this.http.get(Constants.ADMIN.GET_CLIENT, {id: id}).pipe(
			map(response => {
				if (response.valid) {
					return new Client(response.payload);
				} else {
					throw response.message;
				}
			})
		);
	}

	getUserAttributes(id: number): Observable<any[]> {
		return this.http.get(Constants.ADMIN.GET_CLIENT_USER_ATTRIBUTES, {id: id}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public getNewClientCredentials(): Observable<ClientCredentials> {
		return this.http.get(Constants.ADMIN.GET_CLIENT_CREDENTIALS).pipe(
			map(response => {
				if (response.valid) {
					return new ClientCredentials(response.payload);
				} else {
					throw response.message;
				}
			})
		);
	}

	public search(filter: SearchFilter): Observable<SearchResults> {
		return this.http.put(Constants.ADMIN.PUT_SEARCH_CLIENTS, filter, false).pipe(
			map(response => {
				if (response.valid) {
					const res = new SearchResults(response.payload, 'Client');
					res.initPagination(filter.pageNumber);

					return res;
				} else {
					throw response.message;
				}
			})
		);
	}

	public save(client: Client): Observable<number> {
		if (client.id > 0) {
			return this.http.put(Constants.ADMIN.PUT_CLIENT, client, false, {id: client.id}).pipe(
				map(response => {
					if (response.valid) {
						return response.payload;
					} else {
						throw {message: response.message, errorFields: response.errorFields};
					}
				})
			);
		} else {
			return this.http.post(Constants.ADMIN.POST_CLIENT, client, false).pipe(
				map(response => {
					if (response.valid) {
						return response.payload;
					} else {
						throw {message: response.message, errorFields: response.errorFields};
					}
				})
			);
		}
	}

	saveUserAttributes(id: number, userAttributes: any[]): Observable<number> {
		return this.http.put(Constants.ADMIN.PUT_CLIENT_USER_ATTRIBUTES, userAttributes, false, {id: id}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw {message: response.message, errorFields: response.errorFields};
				}
			})
		);
	}

	public delete(id: number): Observable<boolean> {
		return this.http.delete(Constants.ADMIN.DELETE_CLIENT, {id: id}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw {message: response.message, errorFields: response.errorFields};
				}
			})
		);
	}

	public deleteLogo(clientId: string): Observable<boolean> {
		return this.http.delete(Constants.ADMIN.DELETE_CLIENT_LOGO, {clientId: clientId}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw {message: response.message, errorFields: response.errorFields};
				}
			})
		);
	}

	public deleteUserAttributes(id: number): Observable<boolean> {
		return this.http.delete(Constants.ADMIN.DELETE_CLIENT_USER_ATTRIBUTES, {id: id}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw {message: response.message, errorFields: response.errorFields};
				}
			})
		);
	}
}
