<app-page [displayMenuIcon]="false" [logo]="clientUI.logo" [headerBackgroundColor]="clientUI.backgroundColor" [headerTextColor]="clientUI.textColor" *ngIf="clientUI">
	<mat-card>
		<mat-card-title class="form">
			{{ getLabel('template.createProfile.part1') }}
			<ng-container *ngIf="client">
				{{ getLabel('template.createProfile.part2') }} {{client.name}}
			</ng-container>
		</mat-card-title>
		<mat-card-content>
			<div *ngIf="validToken; else formBlock" style="margin: 20px 10px 0;">
				<div class="row" *ngIf="recognizedUser">
					{{ getLabel('template.tokenFlow.welcome') }}
					{{recognizedUser.name}} {{recognizedUser.surname}}.
				</div>

				<div class="row" *ngIf="userHasClient" style="margin-top: 20px;">
					<div class="grid-col-10">
						{{ getLabel('template.tokenFlow.userAlreadyRegistered') }} {{client.name}}.<br/>
						<span>{{ getLabel('template.tokenFlow.error.message1') }}</span>
					</div>
				</div>

				<div class="row" *ngIf="!userHasClient" style="margin-top: 20px;">
					<span>{{ getLabel('template.tokenFlow.error.message2') }}<br/><br/></span>
					<form autocomplete="off" (keydown.enter)="$event.preventDefault()" style="margin-left: -10px;">
						<ng-container *ngTemplateOutlet="clientAttributes"></ng-container>
						<ng-container *ngTemplateOutlet="privacyPolicy"></ng-container>
					</form>
				</div>

				<div class="buttons-row" style="margin-top: 20px; display: grid; grid-template-columns: repeat(10, [col] 10%); margin-right: 20px;">
					<div class="button-container grid-col-10" style="grid-column: auto / span 10; width: 100%; padding: 10px; display: flex; flex-direction: column; align-items: flex-end;">
						<div>
							<button *ngIf="!userHasClient" mat-raised-button color="primary" (click)="continueSignup()"
									  [style]="'background:' + clientUI.backgroundColor + '; color: ' + clientUI.textColor + '; width: 168px; margin-left: 10px;'">
								{{ getLabel('template.tokenFlow.btn.continue') }}
							</button>

							<button mat-raised-button color="primary" (click)="resetToken()"
									  [style]="'background:' + clientUI.backgroundColor + '; color: ' + clientUI.textColor + '; width: 168px; margin-left: 10px;'">
								{{ getLabel('template.tokenFlow.btn.restart') }}
							</button>
						</div>
					</div>
				</div>
			</div>

			<ng-template #formBlock>
				<form autocomplete="off" (keydown.enter)="$event.preventDefault()">

					<div class="row">
						<div class="grid-col-10">
							<h4 class="form">{{ getLabel('template.form.credential') }}</h4>
						</div>
					</div>
					<div class="row">
						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill">
								<mat-label>E-mail *</mat-label>
								<input matInput name="email" type="email" [(ngModel)]="user.email" (focusout)="verifyUser()"
										 autocomplete="none"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="input-container grid-col-5">
							<app-password label="{{ getLabel('template.form.password') }}" name="password" [value]="user.password"
											  (valueChange)="setPassword($event)"></app-password>
						</div>
						<div class="input-container grid-col-5" *ngIf="!userAlreadyExists">
							<app-password label="{{ getLabel('template.form.password.confirm') }}" name="password" [value]="user.passwordConfirm"
											  (valueChange)="setPasswordConfirm($event)"></app-password>
						</div>
					</div>

					<div class="row" *ngIf="!userAlreadyExists">
						<div class="grid-col-10">
							<h4 class="form">{{ getLabel('template.personalInfo') }}</h4>
						</div>
					</div>
					<div class="row" *ngIf="!userAlreadyExists">
						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill">
								<mat-label>{{ getLabel('template.form.name') }} *</mat-label>
								<input matInput name="name" type="text" [(ngModel)]="user.name" autocomplete="none"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>

						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill">
								<mat-label>{{ getLabel('template.form.surname') }} *</mat-label>
								<input matInput name="surname" type="text" [(ngModel)]="user.surname" autocomplete="none"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>
					</div>

					<div class="row" *ngIf="!userAlreadyExists">
						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill">
								<mat-label>{{ getLabel('template.form.address') }}</mat-label>
								<input matInput name="address" type="text" [(ngModel)]="user.address" autocomplete="none"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>

						<div class="input-container grid-col-3">
							<mat-form-field appearance="fill">
								<mat-label>{{ getLabel('template.form.province') }}</mat-label>
								<mat-select name="province" [(ngModel)]="user.municipality.province" (selectionChange)="updateMunicipality($event)" *ngIf="provinces">
									<mat-option [value]="''">{{ getLabel('template.form.choose') }}</mat-option>
									<mat-option *ngFor="let p of provinces" [value]="p.province">{{p.province}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div class="input-container grid-col-2">
							<mat-form-field appearance="fill">
								<mat-label>{{ getLabel('template.form.municipality') }}</mat-label>
								<mat-select name="municipality" [(ngModel)]="user.municipality.name">
									<mat-option [value]="''">{{ getLabel('template.form.choose') }}</mat-option>
									<mat-option *ngFor="let m of municipalities" [value]="m.name">{{m.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<div class="row" *ngIf="!userAlreadyExists">
						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill">
								<mat-label>{{ getLabel('template.form.organization') }}</mat-label>
								<input matInput name="organization" type="text" [(ngModel)]="user.organization"
										 autocomplete="none"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>
						<div class="input-container grid-col-3">
							<mat-form-field appearance="fill">
								<mat-label>{{ getLabel('template.form.organizationType') }}</mat-label>
								<mat-select name="organizationType" [(ngModel)]="user.organizationType.id">
									<mat-option *ngFor="let ot of organizationTypes" [value]="ot.id">{{ot.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="input-container grid-col-2">
							<mat-form-field appearance="fill">
								<mat-label>{{ getLabel('template.form.phone') }}</mat-label>
								<input matInput name="phone" type="tel" [(ngModel)]="user.phone" autocomplete="none"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="grid-col-10">
							<h4 class="form">{{ getLabel('template.form.otherInformation') }}</h4>
						</div>
					</div>

					<ng-container *ngTemplateOutlet="clientAttributes"></ng-container>

					<ng-container *ngTemplateOutlet="privacyPolicy"></ng-container>

					<div class="row">
						<div class="mat-small" style="margin-left: 10px;">(*) {{ getLabel('template.form.required') }}</div>
					</div>

					<div class="row" *ngIf="userAlreadyExists">
						<div class="grid-col-10" style="margin-left: 10px; margin-top: 10px;">
							{{ getLabel('template.form.error') }}
							<span *ngIf="userHasClient">{{ getLabel('template.form.error.message1') }}</span>
							<span *ngIf="!userHasClient">{{ getLabel('template.form.error.message2') }}</span>
						</div>
					</div>

					<div class="buttons-row">
						<div class="button-container grid-col-10">
							<button class="action" mat-raised-button color="primary" (click)="signup()"
									  [disabled]="userHasClient"
									  [style]="'background:' + clientUI.backgroundColor + '; color: ' + clientUI.textColor">
								{{ getLabel('template.form.btn.signup') }}
							</button>
						</div>
					</div>
				</form>
			</ng-template>

			<ng-template #privacyPolicy>
				<div class="row">
					<div class="input-container grid-col-5">
						<mat-label>
							{{ getLabel('template.form.privacy.title') }} *<br/>
							{{ getLabel('template.form.privacy.part1') }}
							<a href="/#/privacy-policy" target="_blank">
								{{ getLabel('template.form.privacy.part2') }}
							</a>
							{{ getLabel('template.form.privacy.part3') }}
						</mat-label>
						<mat-radio-group name="privacyPolicy" [(ngModel)]="user.privacyPolicy" style="margin: 10px 0">
							<mat-radio-button name="privacyPolicy" [value]="true" style="margin-right: 20px;">{{ getLabel('template.form.privacy.yes') }}</mat-radio-button>
							<mat-radio-button name="privacyPolicy" [value]="false">{{ getLabel('template.form.privacy.no') }}</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
			</ng-template>

			<ng-template #clientAttributes>
				<div class="row" *ngIf="client.userAttributes !== null && client.userAttributes.length > 0">
					<div class="input-container grid-col-5" *ngFor="let attribute of client.userAttributes">
						<ng-container [ngSwitch]="attribute.type">
							<ng-container *ngSwitchCase="'SHORT_TEXT'">
								<mat-form-field appearance="fill">
									<mat-label>{{attribute.labels[language]}} {{attribute.required ? '*' : ''}}</mat-label>
									<input matInput name="{{attribute.name}}" type="text" [(ngModel)]="user.attributes[attribute.name]"
											 autocomplete="none"/>
									<mat-hint [innerHTML]="attribute.descriptions[language]"></mat-hint>
								</mat-form-field>
							</ng-container>
							<ng-container *ngSwitchCase="'LONG_TEXT'">
								<mat-form-field appearance="fill">
									<mat-label>{{attribute.labels[language]}} {{attribute.required ? '*' : ''}}</mat-label>
									<textarea matInput name="{{attribute.name}}" rows="3" [(ngModel)]="user.attributes[attribute.name]"
												 autocomplete="none"></textarea>
									<mat-hint [innerHTML]="attribute.descriptions[language]"></mat-hint>
								</mat-form-field>
							</ng-container>
							<ng-container *ngSwitchCase="'NUMBER'">
								<mat-form-field appearance="fill">
									<mat-label>{{attribute.labels[language]}} {{attribute.required ? '*' : ''}}</mat-label>
									<input matInput name="{{attribute.name}}" type="number" [(ngModel)]="user.attributes[attribute.name]"
											 autocomplete="none"/>
									<mat-hint [innerHTML]="attribute.descriptions[language]"></mat-hint>
								</mat-form-field>
							</ng-container>
							<ng-container *ngSwitchCase="'RADIO'">
								<mat-label>{{attribute.labels[language] | uppercase}} {{attribute.required ? '*' : ''}}</mat-label>
								<mat-radio-group name="{{attribute.name}}" [(ngModel)]="user.attributes[attribute.name]" style="margin: 10px 0">
									<mat-radio-button *ngFor="let option of attribute.options" name="{{attribute.name}}" value="{{option.value}}" style="margin-right: 20px;">
										{{option.labels[language]}}</mat-radio-button>
								</mat-radio-group>
								<mat-hint [innerHTML]="attribute.descriptions[language]" style="font-size: 75%;"></mat-hint>
							</ng-container>
							<ng-container *ngSwitchCase="'CHECKBOX'">
								<mat-label style="margin: 10px 0">{{attribute.labels[language] | uppercase}} {{attribute.required ? '*' : ''}}</mat-label>
								<mat-checkbox *ngFor="let option of attribute.options" name="{{attribute.name}}" value="{{option.value}}"
												  [checked]="getCheckboxCustomFieldValue(attribute.name, option.value)"
												  (change)="updateCheckboxCustomField($event, attribute.name, option.value)">{{option.labels[language]}}</mat-checkbox>
								<mat-hint [innerHTML]="attribute.descriptions[language]" style="font-size: 75%;"></mat-hint>
							</ng-container>
							<ng-container *ngSwitchCase="'SELECT'">
								<mat-form-field appearance="fill">
									<mat-label>{{attribute.labels[language]}} {{attribute.required ? '*' : ''}}</mat-label>
									<mat-select name="{{attribute.name}}" [(ngModel)]="user.attributes[attribute.name]">
										<mat-option *ngFor="let option of attribute.options" value="{{option.value}}">{{option.labels[language]}}</mat-option>
									</mat-select>
									<mat-hint [innerHTML]="attribute.descriptions[language]"></mat-hint>
								</mat-form-field>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-template>
		</mat-card-content>
	</mat-card>
</app-page>
