import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../../services/user.service';
import {NotificationsService} from 'angular2-notifications';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

	passwords: any;
	adminFlow: boolean;
	email: string;

	clientUI: any;

	public language: string;
	public labels = {
		'IT': {
			'template.change': 'Cambio password',
			'template.change.message1': 'Per modificare la password è necessario inserire due volte la nuova password.',
			'template.change.message2': 'Per modificare la password è necessario inserire la password attuale.',
			'template.form.currentPassword': 'Password corrente',
			'template.form.newPassword': 'Nuova password',
			'template.form.confirmPassword': 'Conferma la nuova password',
			'template.btn.close': 'Chiudi',
			'template.btn.change': 'Cambia la password',
			'notification.change.title': 'Cambio password',
			'notification.change.message1': 'La password è stata aggiornata con successo.',
			'notification.change.message2': 'Non è stato possibile aggiornare la password.'
		},
		'EN': {
			'template.change': 'Change password',
			'template.change.message1': 'In order to change the password you have to insert the new password twice.',
			'template.change.message2': 'In order to change your password you have to enter your current password.',
			'template.form.currentPassword': 'Current password',
			'template.form.newPassword': 'New password',
			'template.form.confirmPassword': 'New password confirm',
			'template.btn.close': 'Close',
			'template.btn.change': 'Change password',
			'notification.change.title': 'Change password',
			'notification.change.message1': 'The password was updated successfully.',
			'notification.change.message2': 'Unable to update the password.'
		}
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<ChangePasswordComponent>,
		private userService: UserService,
		private notificationsService: NotificationsService
	) {
		this.passwords = {};
		this.adminFlow = data && data.adminFlow || false;
		this.language = data && data.language || 'EN';
		this.email = data && data.email || null;
		this.clientUI = data && data.clientUI || {};
	}

	ngOnInit(): void {
	}

	updatePassword(): void {
		if (this.adminFlow) {
			const payload = {
				email: this.email,
				password: this.passwords.newPassword,
				passwordConfirm: this.passwords.newPasswordConfirm
			};

			this.userService.updatePasswordAsAdmin(payload).subscribe(res => {
				if (res) {
					this.notificationsService.success(this.getLabel('notification.change.title'), this.getLabel('notification.change.message1'));
					this.dialogRef.close();
				} else {
					this.notificationsService.error(this.getLabel('notification.change.title'), this.getLabel('notification.change.message2'));
				}
			});
		} else {
			this.userService.updatePassword(this.passwords).subscribe(res => {
				if (res) {
					this.notificationsService.success(this.getLabel('notification.change.title'), this.getLabel('notification.change.message1'));
					this.dialogRef.close();
				} else {
					this.notificationsService.error(this.getLabel('notification.change.title'), this.getLabel('notification.change.message2'));
				}
			});
		}
	}

	setCurrentPassword(password): void {
		this.passwords.currentPassword = password;
	}

	setNewPassword(password): void {
		this.passwords.newPassword = password;
	}

	setNewPasswordConfirm(password): void {
		this.passwords.newPasswordConfirm = password;
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}
}
