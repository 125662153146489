<div class="footer">
	<div class="leftLogo mat-small">
		<img src="assets/logo/logo-enea.png" title="ENEA" alt="ENEA" role="ENEA"/>
		<div>
			<div>&copy; 2018 - {{ now }}</div>
			<div style="text-align: center;">{{ version ? 'v. ' + version : 'DEV version' }}</div>
		</div>
	</div>

	<div class="center mat-small">
		<span (click)="openLink('feedback')">Feedback</span>
		<span (click)="openLink('accessibility')">{{ getLabel('template.accessibility') }}</span>
		<span (click)="openLink('privacy')">Privacy</span>
	</div>

	<div class="rightLogo mat-small">
		powered by <a href="https://www.heartwoodlabs.com" target="_blank"><img src="assets/logo/logo-heartwood-labs.svg"
																										title="Heartwood Labs"
																										alt="Heartwood Labs"
																										role="link"/></a>
	</div>
</div>
