export class MenuItem {
	icon: string;
	name: string;
	url: string;
	children: MenuItem[];
	displayChildrenStatus: boolean;

	constructor(icon: string, name: string, url: string, children?: MenuItem[]) {
		this.icon = icon;
		this.name = name;
		this.url = url;
		this.children = children;
		this.displayChildrenStatus = false;
	}
}
