import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'app-delete-user-attributes',
	templateUrl: './delete-user-attributes.component.html',
	styleUrls: ['./delete-user-attributes.component.scss']
})
export class DeleteUserAttributesComponent implements OnInit {

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit(): void {
	}

}
