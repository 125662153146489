<app-page [displayMenuIcon]="false">
  <mat-card>
    <mat-card-title class="form">
		 {{ getLabel('template.reset') }}
    </mat-card-title>
    <mat-card-content>
      <form autocomplete="off" (keydown.enter)="$event.preventDefault()">
        <div class="row">
          <div class="input-container grid-col-5">
            <app-password label="Password" name="password" [value]="user.password" (valueChange)="setPassword($event)" style="padding-right: 20px;"></app-password>
          </div>
          <div class="input-container grid-col-5">
            <app-password label="Password" name="password" [value]="user.passwordConfirm" (valueChange)="setPasswordConfirm($event)" style="padding-right: 20px;"></app-password>
          </div>
        </div>
        <div class="buttons-row">
          <div class="button-container grid-col-10">
            <button class="action" mat-raised-button color="primary" (click)="resetPassword()" [disabled]="disableResetButton">{{ getLabel('template.btn.reset') }}</button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</app-page>
