import {Component, OnInit} from '@angular/core';
import {StatusService} from '../../../services/status.service';
import {ActivatedRoute} from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	public now: number;
	public version: string;

	public language: string;
	public labels = {
		'IT': {
			'template.accessibility': 'Accessibilità'
		},
		'EN': {
			'template.accessibility': 'Accessibility'
		}
	};

	constructor(private route: ActivatedRoute, private statusService: StatusService) {
	}

	ngOnInit(): void {
		this.now = new Date().getFullYear();

		this.statusService.getRuntimeInfo().subscribe(res => {
			this.version = res.version;
		});

		this.language = this.route.snapshot.data.language;
	}

	openLink(link: string): void {
		let url: string;
		if (link === 'feedback') {
			url = 'mailto:smartcityplatform.project@enea.it?subject=FeedbackIDP';
		} else if (link === 'accessibility') {
			url = 'https://form.agid.gov.it/view/0f884520-27fe-11ef-9d5c-b7fa7aeb975c';
		} else if (link === 'privacy') {
			url = '/#/privacy-policy';
		}

		window.location.href = url;
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}
}
