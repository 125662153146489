import {SearchFilter} from './search-filter.model';
import {SearchResults} from './search-results.model';

export class TableConfiguration {
	name: string;
	searchFilter: SearchFilter;
	searchResults: SearchResults;
	columns: ColumnConfiguration[];
	actionColumn: ActionColumnConfiguration;
	displayDownload: boolean;
	disableDownload: boolean;
}

export class ColumnConfiguration {
	name: string;
	label: string;
	sortable: boolean;
	cellWriter?: (elem: any) => string[];
}

export class ActionColumnConfiguration {
	label: string;
	icon: string;

	constructor(label: string, icon: string) {
		this.label = label;
		this.icon = icon;
	}
}
