<app-page [displayMenuIcon]="false">
	<mat-card>
		<mat-card-title class="form">
			INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI
		</mat-card-title>
		<mat-card-content>
			<div style="margin-left: 10px;">
				<h2>EX ART. 13 DEL REGOLAMENTO (UE) 679/2016 (General Data Protection Regulation - GDPR)</h2>
				La presente informativa intende offrire ogni informazione utile ad assicurare un trattamento corretto e
				trasparente dei dati personali degli utenti dell’applicativo “Identity Provider IDP - Smart City
				Platform” (di seguito IDP) di ENEA nel rispetto del Regolamento Europeo n. 2016/679 del 27 aprile 2016,
				relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali e alla
				libera circolazione di tali dati (di seguito GDPR).

				<h2>TITOLARE DEL TRATTAMENTO</h2>
				Il Titolare del trattamento è ENEA - Agenzia nazionale per le nuove tecnologie, l'energia e lo sviluppo
				economico sostenibile (di seguito “ENEA” o “Titolare”) con sede a Roma, Lungotevere Thaon di Revel, 76 –
				00196. Può rivolgersi al Titolare del trattamento scrivendo all’indirizzo sopra riportato, o inviando un
				messaggio e-mail al seguente indirizzo di posta elettronica certificata: <a href="mailto:enea@cert.enea.it">enea@cert.enea.it</a>.

				<h2>RESPONSABILE DELLA PROTEZIONE DEI DATI</h2>
				Il Responsabile della Protezione dei Dati (RPD) per ENEA è stato nominato con Disposizione n°34/2020/PRES
				del 6 febbraio 2020. Per comunicazioni relative esclusivamente al trattamento dei dati personali, può
				essere contattato al seguente indirizzo e-mail: <a href="mailto:uver.dpo@enea.it">uver.dpo@enea.it</a>.

				<h2>FINALITÀ E MODALITÀ DEL TRATTAMENTO</h2>
				L’applicativo IDP di ENEA è un sistema di autenticazione degli utenti che consente di accedere a un set
				di piattaforme sviluppate da ENEA (a titolo esemplificativo e non esaustivo: SCP Project, RECON, Smart
				Home), di seguito “Piattaforme Specifiche”. IDP permette di creare e gestire l’”account utente”
				necessario all’utilizzo delle Piattaforme Specifiche che utilizzano questo sistema di autenticazione.
				IDP supporta:
				<ul>
					<li> la condivisione dello stesso “account utente” tra Piattaforme Specifiche (previa registrazione nelle
						stesse);
					</li>
					<li> il sistema di Single-Sign-On, ovvero il sistema di login automatico del browser che avviene passando
						da una Piattaforma Specifica a un’altra (ad es. passando da RECON a Smart Home).
					</li>
				</ul>
				Il servizio dell’IDP è disponibile all’URL <a href="https://idp.smartcityplatform.enea.it/">https://idp.smartcityplatform.enea.it/</a>
				Per la creazione dell’account con IDP, l’utente accede da una delle Piattaforme Specifiche che hanno aderito
				all’IDP (p.es. RECON) e viene quindi indirizzato all’IDP.
				Per la creazione dell’account l’utente inserisce un indirizzo e-mail valido ed una password di sua
				creazione.
				L’indirizzo e-mail potrà essere ulteriormente trattato dal Titolare al fine di:
				<ul>
					<li> consentire il recupero delle credenziali in caso di smarrimento;</li>
					<li> gestire la procedura di autenticazione dell’utente come userid in caso di accessi successivi;</li>
					<li> ricevere materiale informativo sui temi inerenti alle Piattaforme Specifiche aderenti all’IDP.</li>
				</ul>
				In fase di creazione dell’account vengono chiesti altri dati:
				<ul>
					<li> nome, cognome (obbligatori e contrassegnati da asterisco);</li>
					queste informazioni sono immagazzinate dall’ IDP e sono richieste dalle Piattaforme Specifiche e trattate
					secondo le relative specifiche informative;
					<li> indirizzo, organizzazione, telefono (opzionali, non contrassegnati da asterisco);
						queste informazioni sono immagazzinate dall’IDP e potrebbero essere utilizzate dalle Piattaforme
						Specifiche per avere altri dati di contatto dell’utente; per ulteriori informazioni si rinvia alle
						relative specifiche informative.
					</li>
				</ul>
				A seguito della creazione dell’account sull’IDP, l’utente viene reindirizzato verso la Piattaforma Specifica
				di primo accesso; a seconda della Piattaforma Specifica di interesse potrebbero essere richieste ulteriori
				azioni/informazioni necessarie per concludere la registrazione alla Piattaforma e abilitare il servizio
				(come a titolo esemplificativo, l’accettazione delle Condizioni generali di utilizzo della singola
				Piattaforma specifica, alla cui policy privacy specifica si rinvia).
				Dall’interfaccia della Piattaforma Specifica l’utente può accedere con il proprio account, venendo
				reindirizzato sull’IDP.

				<h2>NATURA DEL CONFERIMENTO DEI DATI</h2>
				Il conferimento dei dati personali necessari per creare l’account è facoltativo, tuttavia, in caso di
				mancato conferimento dei dati richiesti, ENEA non sarà in grado di fornire all’Utente il servizio richiesto.
				Per la finalità di cui al punto 3, l’utente può in qualsiasi momento comunicare di non voler essere più
				contattato scrivendo all’indirizzo e-mail della Piattaforma Specifica da cui non vuole più ricevere
				comunicazioni.
				L’indirizzo e-mail è apposto nel messaggio di disiscrizione in calce alle e-mail di newsletter di progetto,
				quando prevista nella relativa Piattaforma Specifica aderente all’IDP.

				<h2>BASE GIURIDICA DEL TRATTAMENTO</h2>
				Il trattamento dei dati personali è effettuato dal Titolare nell’ambito di esecuzione dei propri compiti di
				interesse pubblico ai sensi dell’art. 6, comma 1, lett. e) del GDPR e, in particolare, nella realizzazione
				delle finalità di ricerca e innovazione tecnologica nei settori dell'energia, dell'ambiente e dello sviluppo
				economico sostenibile, in ossequio alla norma istitutiva di ENEA (art. 37, L. 23 luglio 2009, n. 99, come
				modificato dall’art. 4 della legge n. 221/2015) con riferimento al programma “Ricerca di Sistema Elettrico”.
				La "Ricerca di Sistema elettrico" è un programma che prevede un insieme di attività di ricerca e sviluppo
				finalizzate a ridurre il costo dell'energia elettrica per gli utenti finali, migliorare l'affidabilità del
				sistema e la qualità del servizio, ridurre l'impatto del sistema elettrico sull'ambiente, consentire
				l'utilizzo razionale delle risorse energetiche e assicurare al Paese le condizioni per uno sviluppo
				sostenibile.
				Nello specifico, i dati personali saranno trattati senza necessità di consenso (articolo 6, comma 1, lett.
				e) del GDPR), per l’esercizio dei compiti istituzionali affidati ad ENEA e, in particolare, per la
				realizzazione delle finalità di interesse pubblico relative alla ricerca e innovazione tecnologica nel
				settore dell'energia.

				<h2>DATI PERSONALI OGGETTO DEL TRATTAMENTO</h2>
				Le operazioni di trattamento sono svolte con riferimento ai soli dati personali necessari per l’utilizzo
				dell’IDP e delle sue funzionalità. I tipi di dati oggetto di trattamento comprendono, in particolare:
				<ul>
					<li><u>dati obbligatori trattati per la creazione dell’account</u>: indirizzo e-mail, password, nome e
						cognome forniti
						dall’utente (obbligatori);
					</li>
					<li><u>dati opzionali</u>: indirizzo, organizzazione e telefono, che le Piattaforme Specifiche aderenti
						all’IDP
						potrebbero utilizzare per contattare l’utente in modalità alternativa alla e-mail.
					</li>
				</ul>

				<h2>DESTINATARI DEI DATI PERSONALI</h2>
				I dati personali sono comunicati esclusivamente ai soggetti autorizzati al trattamento, che operano sotto la
				diretta autorità e secondo le istruzioni impartite dal Titolare, per l'esecuzione delle attività necessarie.
				I dati personali non saranno oggetto di diffusione, tuttavia, se necessario, potranno essere comunicati:
				<ul>
					<li> ai soggetti a cui la comunicazione dei dati debba essere effettuata in adempimento di un obbligo
						previsto dalla legge, da un regolamento o dalla normativa dell’Unione Europea, ovvero per adempiere ad
						un ordine dell’Autorità Giudiziaria;
					</li>
					<li> ad altri eventuali soggetti terzi, quali enti pubblici o autorità istituzionali, nei casi
						espressamente previsti dalla legge, ovvero ancora se la comunicazione si renderà necessaria per la
						tutela dell’ENEA in sede giudiziaria, nel rispetto delle vigenti disposizioni in materia di protezione
						dei dati personali.
					</li>
				</ul>

				<h2>PERIODO DI CONSERVAZIONE DEI DATI</h2>
				I dati oggetto di trattamento saranno conservati per tutto il tempo necessario ad assicurare la funzionalità
				dell’IDP in relazione alle Piattaforme Specifiche ed al raggiungimento delle relative finalità progettuali.
				I dati potranno essere conservati per una durata ulteriore ai sensi dell’art. 99 Codice Privacy e potranno
				essere condivisi con enti pubblici, privati e con la comunità scientifica in forma di dati aggregati,
				statistiche o risultati di analisi. Tali risultati potranno essere utilizzati in modo anonimo per la
				divulgazione scientifica in varie forme e per le finalità del Programma di ricerca. In particolare i
				risultati della sperimentazione potranno essere successivamente divulgati in forma anonima, in occasione di
				congressi, riunioni scientifiche e pubblicazioni.

				<h2>DIRITTI DELL’INTERESSATO</h2>
				L’interessato dal trattamento ha il diritto di chiedere:
				<ul>
					<li> la conferma che sia o meno in corso un trattamento dei propri dati personali e, in tal caso, di
						ottenerne l’accesso (diritto di accesso);
					</li>
					<li> la rettifica dei dati personali inesatti, o l’integrazione dei dati personali incompleti (diritto di
						rettifica);
					</li>
					<li> cancellazione dei dati, se sussiste uno dei motivi previsti dal Regolamento (diritto all’oblio);
					</li>
					<li> la limitazione del trattamento dei dati quando ricorre una delle ipotesi previste dal Regolamento
						(diritto di limitazione);
					</li>
					<li> di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati e
						di trasmettere, ove tecnicamente possibile, tali dati a un altro titolare del trattamento (diritto
						alla portabilità);
					</li>
					<li> il diritto di revocare il consenso al trattamento dei dati, in qualsiasi momento, senza pregiudicare
						la liceità del trattamento basata sul consenso prestato prima della revoca e di opporsi in qualsiasi
						momento al trattamento per finalità di marketing o diversa finalità ulteriori (diritto di
						opposizione);
					</li>
					<li> il diritto di opporsi, per motivi legittimi, al trattamento dei dati anche qualora pertinenti allo
						scopo della raccolta;
					</li>
					<li> il diritto a non essere sottoposto a una decisione basata unicamente sul trattamento automatizzato
						dei dati personali che producano effetti giuridici che riguardano o ancora che incidano in modo
						analogo significativamente sulla persona dell’interessato.
					</li>
				</ul>
				È possibile esercitare i diritti sopra menzionati in qualunque momento, inoltrando una semplice richiesta a
				questo indirizzo mail <a href="mailto:privacy@enea.it">privacy@enea.it</a>, indicando nell’oggetto “Identity
				Provider IDP - Smart City Platform”.
				L’interessato sarà contattato e informato nel più breve tempo possibile e, comunque, entro 30 (trenta)
				giorni dalla data della tua richiesta.
				Qualora si ritenga violata la normativa in materia di protezione dati personali per quanto concerne il
				trattamento, l’interessato ha il diritto di presentare un reclamo davanti all’Autorità Garante locale per la
				protezione dei dati personali.

				<h2>AGGIORNAMENTO DELL’INFORMATIVA</h2>
				Eventuali modifiche, integrazioni, variazioni o aggiornamenti della presente informativa possono rendersi
				necessari in termini di adeguamento normativo e\o rispetto ad eventuali modifiche tecniche apportate
				all’applicativo e\o ad eventuali variazioni delle finalità o delle modalità del trattamento dei dati. Ogni
				versione aggiornata sarà pubblicata sul sito web dell’applicativo e diventerà efficace nei confronti degli
				utenti subito dopo la sua pubblicazione. È sempre possibile contattare il Titolare del trattamento inviando
				una mail all’indirizzo: <a href="mailto:privacy@enea.it">privacy@enea.it</a>.

				<br/><br/>
				Informativa aggiornata in data 10/12/2021
			</div>
		</mat-card-content>
	</mat-card>
</app-page>
