import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'app-delete-role',
	templateUrl: './delete-role.component.html',
	styleUrls: ['./delete-role.component.scss']
})
export class DeleteRoleComponent implements OnInit {

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit(): void {
	}

}
