<mat-dialog-content>
	<h3>{{ getLabel('template.change') }}</h3>
	<p class="description" *ngIf="adminFlow">
		{{ getLabel('template.change.message1') }}
	</p>
	<p class="description" *ngIf="!adminFlow">
		{{ getLabel('template.change.message2') }}
	</p>
	<form autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<p *ngIf="!adminFlow">
			<app-password label="{{getLabel('template.form.currentPassword')}}" name="currentPassword" [value]="passwords.currentPassword"
							  (valueChange)="setCurrentPassword($event)"></app-password>
		</p>
		<p>
			<app-password label="{{getLabel('template.form.newPassword')}}" name="newPassword" [value]="passwords.newPassword"
							  (valueChange)="setNewPassword($event)"></app-password>
		</p>
		<p>
			<app-password label="{{getLabel('template.form.confirmPassword')}}" name="newPasswordConfirm" [value]="passwords.newPasswordConfirm"
							  (valueChange)="setNewPasswordConfirm($event)"></app-password>
		</p>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div>
		<button mat-raised-button mat-dialog-close>{{ getLabel('template.btn.close') }}</button>
		<button mat-raised-button color="primary" (click)="updatePassword()" [style]="'background:' + clientUI.backgroundColor + '; color: ' + clientUI.textColor">{{ getLabel('template.btn.change') }}</button>
	</div>
</mat-dialog-actions>
