import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

	@Input() overlay: boolean;
	display: boolean;

	constructor(private loaderService: LoaderService) {
	}

	ngOnInit(): void {
		this.loaderService.displayUI.subscribe(
			(display: boolean) => {
				this.display = display;
			},
			(error: any) => {
				this.display = false;
			}
		);
	}

}
