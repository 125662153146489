import {Role} from './role.model';
import {Client} from './client.model';
import {User} from './user.model';
import {PaginationElement} from './pagination-element.model';

export class SearchResults {
	result: any[];
	totalResults: number;
	totalPages: number;
	paginationElements: PaginationElement[];

	constructor(e: any, type: string) {
		this.totalResults = e && e.totalResults !== undefined ? e.totalResults : null;
		this.totalPages = e && e.totalPages !== undefined ? e.totalPages : null;
		this.result = e && e.result !== undefined ? e.result.map(r => this.createResultElement(r, type)) : [];
	}

	private createResultElement(values: any, type: string): any {
		if (type === 'Role') {
			return new Role(values);
		} else if (type === 'Client') {
			return new Client(values);
		} else if (type === 'User') {
			return new User(values);
		}

		return null;
	}

	initPagination(currentPage: number): void {
		const res = [];

		res.push(new PaginationElement(
			1,
			'first_page',
			'icon'
		));

		res.push(new PaginationElement(
			currentPage > 1 ? currentPage - 1 : 1,
			'chevron_left',
			'icon'
		));

		let start = currentPage - 2;
		let end = currentPage + 2;

		if (start <= 0) {
			start = 1;
			end = this.totalPages < 5 ? this.totalPages : 5;
		}

		if (end > this.totalPages) {
			start = this.totalPages - 4 > 1 ? this.totalPages - 4 : 1;
			end = this.totalPages;
		}

		for (let i = start; i <= end; i++) {
			res.push(new PaginationElement(
				i,
				i.toString()
			));
		}

		res.push(new PaginationElement(
			currentPage < this.totalPages ? currentPage + 1 : this.totalPages,
			'chevron_right',
			'icon'
		));

		res.push(new PaginationElement(
			this.totalPages,
			'last_page_page',
			'icon'
		));

		this.paginationElements = res;
	}
}
