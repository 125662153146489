import {Component, OnInit} from '@angular/core';
import {SearchFilter} from '../../../../model/search-filter.model';
import {RoleService} from '../../../../services/role.service';
import {SearchResults} from '../../../../model/search-results.model';
import {Role} from '../../../../model/role.model';
import {TableConfiguration} from '../../../../model/table-configuration.model';
import {NotificationsService} from 'angular2-notifications';
import {MatDialog} from '@angular/material/dialog';
import {DeleteRoleComponent} from '../../../dialogs/admin/delete-role/delete-role.component';
import {Router} from '@angular/router';

@Component({
	selector: 'app-roles-admin',
	templateUrl: './roles-admin.component.html',
	styleUrls: ['./roles-admin.component.scss']
})
export class RolesAdminComponent implements OnInit {

	tableConfiguration: TableConfiguration;
	searchFilter: SearchFilter;
	searchResults: SearchResults;

	constructor(
		private roleService: RoleService,
		private notificationsService: NotificationsService,
		private router: Router,
		private deleteDialog: MatDialog
	) {
	}

	ngOnInit(): void {
		this.searchFilter = new SearchFilter();
		this.searchFilter.pageNumber = 1;
		this.searchFilter.pageSize = '10';
		this.searchFilter.sortField = 'name';
		this.searchFilter.sortDirection = 'ASC';

		this.tableConfiguration = new TableConfiguration();
		this.tableConfiguration.name = 'Roles';
		this.tableConfiguration.searchFilter = this.searchFilter;
		this.tableConfiguration.searchResults = this.searchResults;
		this.tableConfiguration.columns = [
			{name: 'name', label: 'Name', sortable: true}
		];

		this.search();
	}

	updateSearchField(field: string, value: any): void {
		this.searchFilter.fields[field] = value;
	}

	getSearchFieldValue(field: string): any {
		return this.searchFilter.fields[field];
	}

	updatePageSize(): void {
		this.search();
	}

	updateSortField(field: string): void {
		this.search();
	}

	updatePagination(pageNumber: number): void {
		this.search();
	}

	search(): void {
		this.roleService.search(this.searchFilter).subscribe(res => {
			this.tableConfiguration.searchResults = res;
			this.searchResults = res;
		});
	}

	edit(id: number): void {
		this.router.navigateByUrl('/admin/roles/' + id).then(() => {
		});
	}

	delete(id: number): void {
		const dialog = this.deleteDialog.open(
			DeleteRoleComponent, {
				width: '500px'
			}
		);

		dialog.afterClosed().subscribe(res => {
			if (res && res === true) {
				this.roleService.delete(id).subscribe(
					deleteResult => {
						if (deleteResult) {
							this.notificationsService.success('Delete role', 'Role removed successfully.');
							this.search();
						} else {
							this.notificationsService.error('Delete role', 'Unable to remove role.');
						}
					},
					error => {
						this.notificationsService.error('Delete role', 'Unable to remove role: ' + error);
					});
			}
		});
	}

	verifyRole(role: Role): boolean {
		return role.defaultRole && role.defaultRole === true;
	}
}
