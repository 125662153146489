<mat-form-field appearance="fill" [ngClass]="class">
	<mat-label>{{label}}</mat-label>
	<input matInput name="{{name}}" [type]="hidePassword ? 'password' : 'text'" autocomplete="off" [value]="value"
			 (input)="onChange($event)" [readonly]="readonly"/>
	<button mat-icon-button matSuffix (click)="hidePassword = !hidePassword; $event.preventDefault();"
			  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword" *ngIf="!hideAction">
		<mat-icon [ngClass]="{'mat-error': error}">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
	</button>
	<mat-hint *ngIf="hint">{{hint}}</mat-hint>
	<mat-hint *ngIf="error" class="mat-error">{{error}}</mat-hint>
</mat-form-field>
