import {Component, Input, OnInit} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@Input() public mobileQuery: MediaQueryList;
	@Input() public sideNav: MatSidenav;
	@Input() public displayMenuIcon: boolean;
	@Input() public displayAdditionalIcons: boolean;

	@Input() public logo: string;
	@Input() public backgroundColor: string;
	@Input() public textColor: string;

	ngOnInit(): void {
	}

	hideLogo(event: ErrorEvent): void {
		const img = event.target as HTMLElement;
		img.parentElement.style.display = 'none';
	}
}
