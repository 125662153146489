import {Component, OnInit} from '@angular/core';
import {RoleService} from '../../../../services/role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Role} from '../../../../model/role.model';
import {DeleteRoleComponent} from '../../../dialogs/admin/delete-role/delete-role.component';
import {MatDialog} from '@angular/material/dialog';
import {NotificationsService} from 'angular2-notifications';
import {FormError} from '../../../../model/form-error.model';

@Component({
	selector: 'app-roles-edit-admin',
	templateUrl: './roles-edit-admin.component.html',
	styleUrls: ['./roles-edit-admin.component.scss']
})
export class RolesEditAdminComponent implements OnInit {

	role: Role;
	error: FormError;

	constructor(
		private roleService: RoleService,
		private notificationsService: NotificationsService,
		private route: ActivatedRoute,
		private router: Router,
		private deleteDialog: MatDialog) {
	}

	ngOnInit(): void {
		this.init();
	}

	init(): void {
		const roleId: number = parseInt(this.route.snapshot.paramMap.get('roleId'), 10);
		if (roleId === 0) {
			this.role = new Role();
		} else {
			this.roleService.getRole(roleId).subscribe(res => {
				this.role = res;
			});
		}

		this.error = new FormError(['name']);
	}

	goBack(): void {
		this.router.navigateByUrl('/admin/roles').then(() => {
		});
	}

	save(): void {
		this.roleService.save(this.role).subscribe(
			res => {
				this.notificationsService.success('Save role', 'Role saved successfully.');
				setTimeout(() => {
					this.router.navigateByUrl('/admin/roles/' + res).then(() => {
						this.init();
					});
				}, 1000);
			},
			error => {
				this.error.setFields(error.errorFields, error.message);
				this.notificationsService.error('Save role', 'Unable to save role');
			});
	}

	delete(): void {
		const dialog = this.deleteDialog.open(
			DeleteRoleComponent, {
				width: '500px'
			}
		);

		dialog.afterClosed().subscribe(res => {
			if (res && res === true) {
				this.roleService.delete(this.role.id).subscribe(
					deleteResult => {
						if (deleteResult) {
							this.notificationsService.success('Delete role', 'Role removed successfully.');

							setTimeout(() => {
								this.goBack();
							}, 1000);

						} else {
							this.notificationsService.error('Delete role', 'Unable to remove role.');
						}
					},
					error => {
						this.error.setFields(error.errorFields, error.message);
						this.notificationsService.error('Delete role', 'Unable to remove role.');
					});
			}
		});
	}
}
