import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	private authService: AuthService;

	constructor(injector: Injector) {
		this.authService = injector.get(AuthService);
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (AuthService.hasToken()) {
			request = request.clone({
				setHeaders: {
					Authorization: 'Bearer ' + AuthService.getToken()
				}
			});
		}

		return next.handle(request);
	}
}
