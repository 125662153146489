import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {OrganizationType} from '../model/organization-type.model';
import {OrganizationTypeService} from '../services/organization-type.service';

@Injectable({
	providedIn: 'root'
})
export class OrganizationTypeResolver implements Resolve<OrganizationType[]> {

	constructor(private organizationTypeService: OrganizationTypeService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrganizationType[]> {
		return this.organizationTypeService.search();
	}
}
