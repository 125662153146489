<mat-dialog-content>
	<h3>Delete role</h3>
	<p class="description">
		Confirm role delete?
	</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div>
		<button mat-raised-button mat-dialog-close>No</button>
		<button mat-raised-button color="primary" [mat-dialog-close]="true">Yes</button>
	</div>
</mat-dialog-actions>
