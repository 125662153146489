import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Client} from '../../../../model/client.model';
import {Role} from '../../../../model/role.model';
import {TableConfiguration} from '../../../../model/table-configuration.model';
import {SearchFilter} from '../../../../model/search-filter.model';
import {SearchResults} from '../../../../model/search-results.model';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {DeleteUserComponent} from '../../../dialogs/admin/delete-user/delete-user.component';
import {NotificationsService} from 'angular2-notifications';
import {MatDialog} from '@angular/material/dialog';
import {OrganizationType} from '../../../../model/organization-type.model';
import {Municipality} from '../../../../model/municipality.model';
import {MatSelectChange} from '@angular/material/select';
import {MunicipalityService} from '../../../../services/municipality.service';
import flatpickr from 'flatpickr';
import {User} from '../../../../model/user.model';
// import {Italian} from 'flatpickr/dist/l10n/it';

@Component({
	selector: 'app-users-admin',
	templateUrl: './users-admin.component.html',
	styleUrls: ['./users-admin.component.scss']
})
export class UsersAdminComponent implements OnInit {

	tableConfiguration: TableConfiguration;
	searchFilter: SearchFilter;
	searchResults: SearchResults;

	flatpickr = flatpickr;
	datePicker: any;

	clients: Client[];
	roles: Role[];
	organizationTypes: OrganizationType[];
	provinces: Municipality[];
	municipalities: Municipality[];

	constructor(
		private userService: UserService,
		private loaderService: LoaderService,
		private municipalityService: MunicipalityService,
		private notificationsService: NotificationsService,
		private router: Router,
		private route: ActivatedRoute,
		private deleteDialog: MatDialog
	) {
	}

	ngOnInit(): void {
		this.clients = this.route.snapshot.data.clients;
		this.roles = this.route.snapshot.data.roles;
		this.organizationTypes = this.route.snapshot.data.organizationTypes;
		this.provinces = this.route.snapshot.data.provinces;

		this.searchFilter = new SearchFilter();
		this.searchFilter.pageNumber = 1;
		this.searchFilter.pageSize = '10';
		this.searchFilter.sortField = 'email';
		this.searchFilter.sortDirection = 'ASC';

		this.tableConfiguration = new TableConfiguration();
		this.tableConfiguration.name = 'Users';
		this.tableConfiguration.searchFilter = this.searchFilter;
		this.tableConfiguration.searchResults = this.searchResults;
		this.tableConfiguration.columns = [
			{name: 'email', label: 'E-mail', sortable: true},
			{name: 'name', label: 'Name', sortable: true},
			{name: 'surname', label: 'Surname', sortable: true},
			{name: 'organization', label: 'Organization', sortable: true},
			{name: 'roles', label: 'Roles', sortable: false, cellWriter: (elem: User) => {
					const roles: string[] = [];
					for (const item of elem.userClients) {
						roles.push(item.client.name + ': ' + item.role);
					}
					return roles.sort();
				}
			}
		];
		this.tableConfiguration.displayDownload = true;

		this.initDatePicker();

		this.search();
	}

	search(): void {
		this.loaderService.displayLoader();
		this.userService.search(this.searchFilter).subscribe(res => {
			this.tableConfiguration.searchResults = res;
			this.searchResults = res;

			this.loaderService.hideLoader();
		});
	}

	updateSearchField(field: string, value: any): void {
		if (field === 'roleId' || field === 'clientId' || field === 'organizationTypeId') {
			value = parseInt(value, 10);
		}

		this.searchFilter.pageNumber = 1;
		this.searchFilter.fields[field] = value;
	}

	getSearchFieldValue(field: string): any {
		let res = this.searchFilter.fields[field];
		if (res && (field === 'roleId' || field === 'clientId')) {
			res = res.toString();
		}

		return res;
	}

	updatePageSize(): void {
		this.search();
	}

	updateSortField(field: string): void {
		this.search();
	}

	updatePagination(pageNumber: number): void {
		this.search();
	}

	edit(id: number): void {
		this.router.navigateByUrl('/admin/users/' + id).then(() => {
		});
	}

	delete(id: number): void {
		const dialog = this.deleteDialog.open(
			DeleteUserComponent, {
				width: '500px'
			}
		);

		dialog.afterClosed().subscribe(res => {
			if (res && res === true) {
				this.userService.delete(id).subscribe(
					deleteResult => {
						if (deleteResult) {
							this.notificationsService.success('Delete user', 'User removed successfully.');
							this.search();
						} else {
							this.notificationsService.error('Delete user', 'Unable to remove user.');
						}
					},
					error => {
						this.notificationsService.error('Delete user', 'Unable to remove user: ' + error);
					});
			}
		});
	}

	download(id: number): void {
		this.userService.export(this.searchFilter).subscribe(res => {
			const url = URL.createObjectURL(res.payload);

			const link = document.createElement('a');
			link.href = url;
			link.download = 'users.xlsx';
			link.click();

			URL.revokeObjectURL(url);

			this.tableConfiguration.disableDownload = false;
		}, error => {
			this.notificationsService.error('Export users', 'Unable to export users. ' + error);

			this.tableConfiguration.disableDownload = false;
		});
	}

	updateMunicipality(event: MatSelectChange): void {
		const province = event.value;

		if (province !== '') {
			this.municipalityService.getMunicipalities(province).subscribe(res => {
				this.municipalities = res;
			});
		}
	}

	initDatePicker(): void {
		setTimeout(() => {
			this.datePicker = flatpickr('#registrationDatePicker', {
				dateFormat: 'd/m/Y',
				mode: 'range',
				// locale: Italian,
				weekNumbers: true,
				defaultDate: [this.getSearchFieldValue('registrationDateStart'), this.getSearchFieldValue('registrationDateEnd')],
				onChange: (selectedDates) => {
					if (selectedDates && selectedDates.length > 0) {
						const start = selectedDates[0].valueOf();
						const end = selectedDates.length === 2 ? selectedDates[1].valueOf() : null;

						this.updateSearchField('registrationDateStart', start);
						this.updateSearchField('registrationDateEnd', end);

						this.search();
					}
				}
			});

		}, 200);
	}
}
