import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {Options} from 'angular2-notifications';

@Component({
	selector: 'app-page',
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {

	public mobileQuery: MediaQueryList;
	private readonly mobileQueryListener: () => void;
	public notificationOptions: Options;

	@Input() public displayMenuIcon: boolean;
	@Input() public displayAdditionalIcons: boolean;
	@Input() public width: string;
	@Input() public logo: string;
	@Input() public headerBackgroundColor: string;
	@Input() public headerTextColor: string;

	constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this.mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addEventListener('change', this.mobileQueryListener);

		this.notificationOptions = {
			timeOut: 3000,
			showProgressBar: true,
			pauseOnHover: true,
			clickToClose: true,
			position: ['top', 'right']
		};

		if (!this.headerBackgroundColor) {
			this.headerBackgroundColor = '#3f51b5';
		}

		if (!this.headerTextColor) {
			this.headerTextColor = 'white';
		}
	}

	ngOnInit(): void {
		if (!this.width) {
			this.width = 'regular';
		}
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
	}
}
