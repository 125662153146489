<app-page [displayMenuIcon]="true" [displayAdditionalIcons]="true">
	<mat-card>
		<mat-card-title class="form">
			EDIT CLIENT
		</mat-card-title>
		<mat-card-content *ngIf="client">
			<div class="edit-container">
				<form autocomplete="off" (keydown.enter)="$event.preventDefault()">
					<div class="row">
						<div class="input-container grid-col-8">
							<mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': error.is('name')}">
								<mat-label>Name</mat-label>
								<input matInput name="name" type="text" [(ngModel)]="client.name" [disabled]="isDisabled()" autocomplete="off" (keydown)="error.reset('name')" />
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
								<mat-hint class="mat-error">{{error.get('name')}}</mat-hint>
							</mat-form-field>
						</div>

						<div class="input-container grid-col-2">
							<mat-form-field appearance="fill">
								<mat-label>SCP</mat-label>
								<mat-select name="scp" [(ngModel)]="client.scp" (selectionChange)="updateCallbacks($event)">
									<mat-option [value]="true">Yes</mat-option>
									<mat-option [value]="false">No</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="grid-col-10">
							<h4 class="form">
								Credentials
								<mat-icon class="update-action" title="Generate new credentials" (click)="refreshClientCredentials()">update</mat-icon>
							</h4>
						</div>
					</div>

					<div class="row">
						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': error.is('clientId')}">
								<mat-label>Client ID</mat-label>
								<input matInput name="clientId" type="text" [(ngModel)]="client.clientId" [readonly]="true" autocomplete="off" (keydown)="error.reset('name')" />
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
								<mat-hint class="mat-error">{{error.get('clientId')}}</mat-hint>
							</mat-form-field>
						</div>
						<div class="input-container grid-col-5">
							<app-password label="Client secret" name="clientSecret" [value]="client.clientSecret" (valueChange)="setClientSecret($event)"
											  [class]="{'mat-form-field-invalid': error.is('clientSecret')}" [error]="error.get('clientSecret')" [readonly]="true"></app-password>
						</div>
					</div>

					<div class="row">
						<div class="grid-col-10">
							<h4 class="form">Callbacks</h4>
						</div>
					</div>

					<div class="row">
						<div class="input-container grid-col-10">
							<mat-form-field appearance="fill">
								<mat-label>Login</mat-label>
								<input matInput name="loginCallbackUrl" type="text" [(ngModel)]="client.loginCallbackUrl" [disabled]="isDisabled()" autocomplete="off"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="input-container grid-col-10">
							<mat-form-field appearance="fill">
								<mat-label>Logout</mat-label>
								<input matInput name="logoutCallbackUrl" type="text" [(ngModel)]="client.logoutCallbackUrl" [disabled]="isDisabled()" autocomplete="off"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="input-container grid-col-10">
							<mat-form-field appearance="fill">
								<mat-label>Profile</mat-label>
								<input matInput name="profileCallbackUrl" type="text" [(ngModel)]="client.profileCallbackUrl" [disabled]="isDisabled()" autocomplete="off"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="grid-col-10">
							<h4 class="form">Custom UI</h4>
						</div>
					</div>

					<!-- https://pivan.github.io/file-upload/ -->
					<div class="row">
						<div class="input-container grid-col-10">
							<div style="margin-bottom: 5px;">
								<mat-label style="text-transform: uppercase">Logo</mat-label>
							</div>
							<div style="margin-bottom: 5px;">
								<img [src]="uploadedFile | async" alt="Logo preview" title="Click the image to delete the logo" *ngIf="client.logo || fileUpload.size > 0" style="max-width: 100px; cursor: pointer;" (click)="deleteLogo()" (error)="getLogoPlaceholder()" />
							</div>
							<div>
								<file-upload [control]="fileUpload" simple></file-upload>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</div>
						</div>
					</div>

					<!-- https://www.npmjs.com/package/ngx-color-picker/v/11.0.0, https://github.com/pIvan/ngx-color-picker -->
					<div class="row">
						<div class="input-container grid-col-5">
							<mat-label style="text-transform: uppercase">Main color</mat-label>
							<mat-form-field appearance="standard">
								<input matInput name="headerColor" [(colorPicker)]="client.headerBackgroundColor" [style.background-color]="client.headerBackgroundColor">
							</mat-form-field>
						</div>
						<div class="input-container grid-col-5">
							<mat-label style="text-transform: uppercase">Text color</mat-label>
							<mat-form-field appearance="standard">
								<input matInput name="headerTextColor" [(colorPicker)]="client.headerTextColor" [style.background-color]="client.headerTextColor">
							</mat-form-field>
						</div>
					</div>
				</form>
				<div class="edit-actions-container">
					<button mat-raised-button color="primary" class="edit-action" (click)="goBack()">BACK TO LIST</button>
					<button mat-raised-button color="primary" class="edit-action" [disabled]="isDisabled()" (click)="save()">SAVE</button>
					<button *ngIf="client.id > 0" mat-raised-button color="warn" class="edit-action" [disabled]="isDisabled()" (click)="delete()">DELETE</button>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</app-page>
