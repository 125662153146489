<app-page [displayMenuIcon]="true" [displayAdditionalIcons]="true">
	<mat-card class="spacer">
		<mat-card-content>
			This section allows you to manage the USERS that use the identity provider, through the four basic CRUD
			functionalities (Create, Read, Update, Delete).
		</mat-card-content>
	</mat-card>

	<mat-card class="spacer">
		<div class="table-filters">
			<div class="table-filters-title">Filters</div>
			<div class="table-filters-container">
				<div class="row">
					<mat-form-field appearance="fill">
						<mat-label>E-mail</mat-label>
						<input matInput name="email" type="text" autocomplete="off" (focusout)="search()"
								 (keydown.enter)="search()" [ngModel]="getSearchFieldValue('email')"
								 (ngModelChange)="updateSearchField('email', $event)"/>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Organization</mat-label>
						<input matInput name="organization" type="text" autocomplete="off" (focusout)="search()"
								 (keydown.enter)="search()" [ngModel]="getSearchFieldValue('organization')"
								 (ngModelChange)="updateSearchField('organization', $event)"/>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Organization Type</mat-label>
						<mat-select (selectionChange)="search()" [ngModel]="getSearchFieldValue('organizationTypeId')"
										(ngModelChange)="updateSearchField('organizationTypeId', $event)">
							<mat-option>--- No Selection ---</mat-option>
							<mat-option *ngFor="let ot of organizationTypes" [value]="ot.id">{{ot.name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Role</mat-label>
						<mat-select (selectionChange)="search()" [ngModel]="getSearchFieldValue('roleId')"
										(ngModelChange)="updateSearchField('roleId', $event)">
							<mat-option>--- No Selection ---</mat-option>
							<mat-option *ngFor="let role of roles" value="{{role.id}}">{{role.name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Client</mat-label>
						<mat-select (selectionChange)="search()" [ngModel]="getSearchFieldValue('clientId')"
										(ngModelChange)="updateSearchField('clientId', $event)">
							<mat-option>--- No Selection ---</mat-option>
							<mat-option *ngFor="let client of clients" value="{{client.id}}">{{client.name}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="row">
					<mat-form-field appearance="fill">
						<mat-label>Province</mat-label>
						<mat-select name="province" [ngModel]="getSearchFieldValue('province')"
										(selectionChange)="updateMunicipality($event); search()"
										(ngModelChange)="updateSearchField('province', $event)">
							<mat-option [value]="''">--- No Selection ---</mat-option>
							<mat-option *ngFor="let p of provinces" [value]="p.province">{{p.province}}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Municipality</mat-label>
						<mat-select name="municipality" [ngModel]="getSearchFieldValue('municipality')"
										(selectionChange)="search()"
										(ngModelChange)="updateSearchField('municipality', $event)">
							<mat-option [value]="''">--- No Selection ---</mat-option>
							<mat-option *ngFor="let m of municipalities" [value]="m.name">{{m.name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Registration date</mat-label>
						<input matInput id="registrationDatePicker" name="registrationDatePicker" />
						<mat-icon matSuffix style="width: 35px;">calendar_today</mat-icon>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Custom field name</mat-label>
						<input matInput name="attributeName" type="text" autocomplete="off" (focusout)="search()"
								 (keydown.enter)="search()" [ngModel]="getSearchFieldValue('attributeName')"
								 (ngModelChange)="updateSearchField('attributeName', $event)"/>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Custom field value</mat-label>
						<input matInput name="attributeValue" type="text" autocomplete="off" (focusout)="search()"
								 (keydown.enter)="search()" [ngModel]="getSearchFieldValue('attributeValue')"
								 (ngModelChange)="updateSearchField('attributeValue', $event)"/>
					</mat-form-field>
				</div>
			</div>
		</div>
	</mat-card>

	<mat-card>
		<app-table [configuration]="tableConfiguration"
					  (pageSize)="updatePageSize()" (sort)="updateSortField($event)" (pagination)="updatePagination($event)"
					  (edit)="edit($event)" (delete)="delete($event)" (download)="download($event)"></app-table>
	</mat-card>
</app-page>
