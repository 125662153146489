import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LanguageResolver implements Resolve<string> {
	private defaultLanguage = 'IT';

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
		if (route.queryParamMap.has('l')) {
			return of(route.queryParamMap.get('l').toUpperCase());
		}

		return of(this.defaultLanguage);
	}
}
