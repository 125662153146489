export class Role {
	id: number;
	name: string;
	defaultRole: boolean;

	constructor(e?: any) {
		this.id = e && e.id !== undefined ? e.id : null;
		this.name = e && e.name !== undefined ? e.name : null;
		this.defaultRole = e && e.defaultRole !== undefined ? e.defaultRole : null;
	}
}
