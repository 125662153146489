export class StatsResponse {
	applications: number;
	users: number;
	sessions: number;

	constructor(applications: number, users: number, sessions: number) {
		this.applications = applications;
		this.users = users;
		this.sessions = sessions;
	}

	format(i: number): string {
		return i.toLocaleString('it-IT');
	}
}
