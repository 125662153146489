import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/pages/admin/home/home.component';
import {LoginComponent} from './components/pages/hmi/login/login.component';
import {SignupComponent} from './components/pages/hmi/signup/signup.component';
import {ValidateEmailComponent} from './components/pages/hmi/validate-email/validate-email.component';
import {ResetPasswordComponent} from './components/pages/hmi/reset-password/reset-password.component';
import {LogoutComponent} from './components/pages/hmi/logout/logout.component';
import {ProfileComponent} from './components/pages/hmi/profile/profile.component';
import {DeleteComponent} from './components/pages/hmi/delete/delete.component';
import {AuthGuard} from './guards/auth.guard';
import {LoginDispatcherComponent} from './components/pages/admin/login-dispatcher/login-dispatcher.component';
import {RolesAdminComponent} from './components/pages/admin/roles-admin/roles-admin.component';
import {LogoutDispatcherComponent} from './components/pages/admin/logout-dispatcher/logout-dispatcher.component';
import {ProfileDispatcherComponent} from './components/pages/admin/profile-dispatcher/profile-dispatcher.component';
import {RolesEditAdminComponent} from './components/pages/admin/roles-edit-admin/roles-edit-admin.component';
import {ClientsAdminComponent} from './components/pages/admin/clients-admin/clients-admin.component';
import {ClientsEditAdminComponent} from './components/pages/admin/clients-edit-admin/clients-edit-admin.component';
import {UsersAdminComponent} from './components/pages/admin/users-admin/users-admin.component';
import {UsersEditAdminComponent} from './components/pages/admin/users-edit-admin/users-edit-admin.component';
import {ClientResolver} from './resolvers/client.resolver';
import {RoleResolver} from './resolvers/role.resolver';
import {PrivacyPolicyComponent} from './components/pages/hmi/privacy-policy/privacy-policy.component';
import {OrganizationTypeResolver} from './resolvers/organization-type.resolver';
import {MunicipalityResolver} from './resolvers/municipality.resolver';
import {AnalyticsComponent} from './components/pages/admin/analytics/analytics.component';
import {UserAttributesEditAdminComponent} from './components/pages/admin/user-attributes-edit-admin/user-attributes-edit-admin.component';
import {LanguageResolver} from './resolvers/language.resolver';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'admin/home',
		pathMatch: 'full',
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/home',
		component: HomeComponent,
		canActivate: [AuthGuard],
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/login-dispatcher',
		component: LoginDispatcherComponent,
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/logout-dispatcher',
		component: LogoutDispatcherComponent,
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/profile-dispatcher',
		component: ProfileDispatcherComponent,
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/users',
		component: UsersAdminComponent,
		canActivate: [AuthGuard],
		resolve: {
			roles: RoleResolver,
			clients: ClientResolver,
			organizationTypes: OrganizationTypeResolver,
			provinces: MunicipalityResolver,
			language: LanguageResolver
		}
	},
	{
		path: 'admin/users/:userId',
		component: UsersEditAdminComponent,
		canActivate: [AuthGuard],
		resolve: {
			roles: RoleResolver,
			clients: ClientResolver,
			organizationTypes: OrganizationTypeResolver,
			provinces: MunicipalityResolver,
			language: LanguageResolver
		}
	},
	{
		path: 'admin/roles',
		component: RolesAdminComponent,
		canActivate: [AuthGuard],
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/roles/:roleId',
		component: RolesEditAdminComponent,
		canActivate: [AuthGuard],
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/clients',
		component: ClientsAdminComponent,
		canActivate: [AuthGuard],
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/clients/:id',
		component: ClientsEditAdminComponent,
		canActivate: [AuthGuard],
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/clients/:id/user-attributes',
		component: UserAttributesEditAdminComponent,
		canActivate: [AuthGuard],
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'admin/analytics',
		component: AnalyticsComponent,
		canActivate: [AuthGuard],
		resolve: {
			clients: ClientResolver,
			language: LanguageResolver
		}
	},
	{
		path: 'login/:clientId',
		component: LoginComponent,
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'signup/:clientId/:roleName',
		component: SignupComponent,
		resolve: {
			organizationTypes: OrganizationTypeResolver,
			provinces: MunicipalityResolver,
			language: LanguageResolver
		}
	},
	{
		path: 'profile/:clientId',
		component: ProfileComponent,
		resolve: {
			organizationTypes: OrganizationTypeResolver,
			provinces: MunicipalityResolver,
			language: LanguageResolver
		}
	},
	{
		path: 'validate-email/:validationToken',
		component: ValidateEmailComponent,
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'reset-password/:resetToken',
		component: ResetPasswordComponent,
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'logout/:clientId',
		component: LogoutComponent,
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'delete/:clientId',
		component: DeleteComponent,
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'delete-all/:clientId',
		component: DeleteComponent,
		resolve: {
			language: LanguageResolver
		}
	},
	{
		path: 'privacy-policy',
		component: PrivacyPolicyComponent,
		resolve: {
			language: LanguageResolver
		}
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: true})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
