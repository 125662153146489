<app-page [displayMenuIcon]="false" [width]="'small'" [logo]="clientUI.logo" [headerBackgroundColor]="clientUI.backgroundColor" [headerTextColor]="clientUI.textColor" *ngIf="clientUI">
  <mat-card>
    <mat-card-title class="header">
      <div class="header">
        <mat-icon class="header" [style]="'box-shadow: 0px 0px 2px ' + clientUI.backgroundColor + '; background-color: '+ clientUI.backgroundColor + ';'">person</mat-icon>
      </div>

		 {{ getLabel('template.signIn') }}
    </mat-card-title>
    <mat-card-content>
      <form autocomplete="off" (keydown.enter)="$event.preventDefault()">
        <p>
          <mat-form-field appearance="fill">
            <mat-label>E-mail</mat-label>
            <input matInput name="email" type="email" [(ngModel)]="email" autocomplete="off"/>
            <mat-icon matSuffix style="width: 35px;"></mat-icon>
          </mat-form-field>
        </p>

        <p>
          <app-password label="Password" name="password" [value]="password" (valueChange)="setPassword($event)"></app-password>
        </p>
        <button mat-raised-button color="primary" class="submit" (click)="login($event)" [style]="'background:' + clientUI.backgroundColor + '; color: ' + clientUI.textColor">Login</button>
        <div class="mat-small action" (click)="forgotPassword()">
          <span>{{ getLabel('template.forgotPassword') }}</span>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</app-page>
