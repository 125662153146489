import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoaderService} from '../../../../services/loader.service';
import {ClientService} from '../../../../services/client.service';
import {NotificationsService} from 'angular2-notifications';
import {AuthService} from '../../../../services/auth.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

	public clientUI: any;

	public language: string;
	public labels = {
		'IT': {
			'template.signOut': 'ESCI',
			'template.body': 'Attendete di essere disconnessi dal sistema.',
			'notification.signOut.title': 'Esci',
			'notification.signOut.message1': 'Siete già scollegati dal sistema.',
			'notification.signOut.message2': 'La sessione è già scaduta.',
			'notification.invalid.title': 'Parametri non validi',
			'notification.invalid.message': 'Non è stato possibile scollegarsi dal sistema.',
		},
		'EN': {
			'template.signOut': 'SIGN OUT',
			'template.body': 'Please wait while you are logged out from the system.',
			'notification.signOut.title': 'Sign out',
			'notification.signOut.message1': 'You are already logged out.',
			'notification.signOut.message2': 'You session is already expired.',
			'notification.invalid.title': 'Invalid parameters',
			'notification.invalid.message': 'Unable to log out from the system.',
		}
	};

	constructor(
		private route: ActivatedRoute,
		private clientService: ClientService,
		private authService: AuthService,
		private loaderService: LoaderService,
		private notificationsService: NotificationsService
	) {
	}

	ngOnInit(): void {
		this.loaderService.displayLoader();

		this.language = this.route.snapshot.data.language;

		const token = AuthService.getToken();
		const clientId: string = this.route.snapshot.paramMap.get('clientId');

		if (!token || token.trim() === '') {
			this.loaderService.hideLoader();
			this.notificationsService.warn(this.getLabel('notification.signOut.title'), this.getLabel('notification.signOut.message1'));
		} else {
			this.clientService.getClientByClientId(clientId).subscribe(
				client => {
					this.clientUI = ClientService.getClientUI(client);

					this.authService.logout(clientId).subscribe(
						jws => {
							if (jws) {
								AuthService.removeToken();
								AuthService.removeUser();
								window.location.href = this.buildUrl(client.logoutCallbackUrl, jws);
							} else {
								this.loaderService.hideLoader();
								this.notificationsService.error(this.getLabel('notification.invalid.title'), this.getLabel('notification.invalid.message'));
							}
						},
						error => {
							AuthService.removeToken();
							AuthService.removeUser();
							this.loaderService.hideLoader();
							this.notificationsService.warn(this.getLabel('notification.signOut.title'), this.getLabel('notification.signOut.message2'));
						});
				},
				error => {
					AuthService.removeToken();
					AuthService.removeUser();
					this.loaderService.hideLoader();
					this.notificationsService.error(this.getLabel('notification.invalid.title'), this.getLabel('notification.invalid.message'));
				}
			);
		}
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}

	private buildUrl(url: string, token: string): string {
		let prefix = '?';
		if (url.indexOf('?') > 0) {
			prefix = '&';
		}

		return url + prefix + 'token=' + token;
	}
}
