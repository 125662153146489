import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {SearchFilter} from '../model/search-filter.model';
import {RoleService} from '../services/role.service';
import {Role} from '../model/role.model';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class RoleResolver implements Resolve<Role[]> {
	private searchFilter = new SearchFilter();

	constructor(private roleService: RoleService) {
		this.searchFilter.pageNumber = 1;
		this.searchFilter.pageSize = '10';
		this.searchFilter.sortField = 'name';
		this.searchFilter.sortDirection = 'ASC';
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Role[]> {
		return this.roleService.search(this.searchFilter).pipe(
			map(res => {
				return res.result;
			})
		);
	}
}
