import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpResponse} from '../model/http-response.model';

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	constructor(private http: HttpClient) {
	}

	public get(url: string, replaceParams?: any, queryParams?: any): Observable<HttpResponse> {
		const hds = {'Accept': 'application/json, text/plain, */*'};
		const defaultHeaders = new HttpHeaders(hds);
		let params = new HttpParams();

		if (queryParams) {
			for (const p in queryParams) {
				if (queryParams.hasOwnProperty(p)) {
					params = params.append(p, queryParams[p]);
				}
			}
		}

		return this.http.get(this.replaceUrlParams(url, replaceParams), {
			params: params,
			headers: defaultHeaders,
			observe: 'response'
		}).pipe(
			map(response => this.handleResponse(response)),
			catchError(this.handleError(new HttpResponse()))
		);
	}

	public post(url: string, formData: any, isFormEncoded: boolean, replaceParams?: any): Observable<HttpResponse> {
		let hds = null;

		if (isFormEncoded === true) {
			hds = {'Content-Type': 'application/x-www-form-urlencoded', Accept: 'application/json, text/plain, */*'};
		} else {
			hds = {'Content-Type': 'application/json', Accept: 'application/json, text/plain, */*'};
		}

		const defaultHeaders = new HttpHeaders(hds);

		return this.http.post(this.replaceUrlParams(url, replaceParams), formData, {
			headers: defaultHeaders,
			observe: 'response'
		}).pipe(
			map(response => this.handleResponse(response)),
			catchError(this.handleError(new HttpResponse()))
		);
	}

	public put(url: string, formData: any, isFormEncoded: boolean, replaceParams?: any): Observable<HttpResponse> {
		let hds = null;

		if (isFormEncoded === true) {
			hds = {'Content-Type': 'application/x-www-form-urlencoded', Accept: 'application/json, text/plain, */*'};
		} else {
			hds = {'Content-Type': 'application/json', Accept: 'application/json, text/plain, */*'};
		}

		const defaultHeaders = new HttpHeaders(hds);

		return this.http.put(this.replaceUrlParams(url, replaceParams), formData, {
			headers: defaultHeaders,
			observe: 'response'
		}).pipe(
			map(response => this.handleResponse(response)),
			catchError(this.handleError(new HttpResponse()))
		);
	}

	public delete(url: string, replaceParams?: any): Observable<HttpResponse> {
		const hds = {'Content-Type': 'application/x-www-form-urlencoded', Accept: 'application/json, text/plain, */*'};
		const defaultHeaders = new HttpHeaders(hds);

		return this.http.delete(this.replaceUrlParams(url, replaceParams), {
			headers: defaultHeaders,
			observe: 'response'
		}).pipe(
			map(response => this.handleResponse(response)),
			catchError(this.handleError(new HttpResponse()))
		);
	}

	public download(url: string, formData: any, replaceParams?: any): Observable<HttpResponse> {
		const hds = {'Content-Type': 'application/json', Accept: '*/*'};
		const defaultHeaders = new HttpHeaders(hds);

		return this.http.put(this.replaceUrlParams(url, replaceParams), formData, {
			headers: defaultHeaders,
			observe: 'response',
			responseType: 'blob'
		}).pipe(
			map(response => this.handleDownloadResponse(response)),
			catchError(this.handleError(new HttpResponse()))
		);
	}

	private handleResponse(response: any): HttpResponse {
		const res = new HttpResponse(response.body);

		res.valid = response.status === 200 && response.ok === true;
		res.status = response.status;
		res.statusText = response.statusText;

		return res;
	}

	private handleDownloadResponse(response: any): HttpResponse {
		const res = new HttpResponse();

		res.valid = response.status === 200 && response.ok === true;
		res.status = response.status;
		res.statusText = response.statusText;
		res.payload = response.body;

		return res;
	}

	private handleError(result: HttpResponse): (error: any) => Observable<HttpResponse> {
		return (error: any): Observable<HttpResponse> => {
			result.valid = false;
			result.status = error.status;
			result.statusText = error.error && error.error.payload ? error.error.payload : error.statusText;
			result.code = error.error.code;
			result.message = error.error.message;
			result.errorFields = error.error.errorFields;

			return of(result);
		};
	}

	private replaceUrlParams(url: string, replaceParams: any): string {
		if (replaceParams) {
			for (const p in replaceParams) {
				if (replaceParams.hasOwnProperty(p)) {
					url = url.replace('{{' + p + '}}', replaceParams[p]);
				}
			}
		}

		return url;
	}
}
