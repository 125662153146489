import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../../model/user.model';
import {NotificationsService} from 'angular2-notifications';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../../services/user.service';
import {ChangePasswordComponent} from '../../../dialogs/hmi/change-password/change-password.component';
import {LoaderService} from '../../../../services/loader.service';
import {Client} from '../../../../model/client.model';
import {Role} from '../../../../model/role.model';
import {DeleteUserComponent} from '../../../dialogs/admin/delete-user/delete-user.component';
import {FormError} from '../../../../model/form-error.model';
import {OrganizationType} from '../../../../model/organization-type.model';
import flatpickr from 'flatpickr';
// import {Italian} from 'flatpickr/dist/l10n/it.js';
import {MatSelectChange} from '@angular/material/select';
import {Municipality} from '../../../../model/municipality.model';
import {MunicipalityService} from '../../../../services/municipality.service';
import JSONEditor from 'jsoneditor';
import {UserClient} from '../../../../model/user-client.model';

@Component({
	selector: 'app-users-edit-admin',
	templateUrl: './users-edit-admin.component.html',
	styleUrls: ['./users-edit-admin.component.scss']
})
export class UsersEditAdminComponent implements OnInit {

	user: User;
	error: FormError;

	flatpickr = flatpickr;
	datePicker: any;

	jsonEditor: JSONEditor;

	clients: Client[];
	roles: Role[];
	organizationTypes: OrganizationType[];
	provinces: Municipality[];
	municipalities: Municipality[];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private municipalityService: MunicipalityService,
		private loaderService: LoaderService,
		private notificationsService: NotificationsService,
		private deleteDialog: MatDialog,
		private changePasswordDialog: MatDialog
	) {
	}

	ngOnInit(): void {
		this.clients = this.route.snapshot.data.clients;
		this.roles = this.route.snapshot.data.roles;
		this.organizationTypes = this.route.snapshot.data.organizationTypes;
		this.provinces = this.route.snapshot.data.provinces;

		this.init();
		this.initJsonEditor();
	}

	init(): void {
		const userId: number = parseInt(this.route.snapshot.paramMap.get('userId'), 10);
		if (userId === 0) {
			this.user = new User();
			this.user.userClients = [];
			this.user.emailConfirmed = false;
			this.user.organizationType = this.organizationTypes.filter(ot => ot.name === 'Altro')[0];
			this.user.municipality = new Municipality();

			this.initDatePicker();
			this.buildMunicipalities();
			this.addUserClient();
		} else {
			this.userService.getUser(userId).subscribe(res => {
				this.user = res;

				if (this.user.organizationType == null || !this.user.organizationType.id) {
					this.user.organizationType = this.organizationTypes.filter(ot => ot.name === 'Altro')[0];
				}

				if (this.user.emailConfirmed === null) {
					this.user.emailConfirmed = false;
				}

				if (this.user.municipality == null) {
					this.user.municipality = new Municipality();
				}

				this.initDatePicker();
				this.buildMunicipalities();
				this.sortUserClients();
			});
		}

		this.error = new FormError(['email', 'password', 'passwordConfirm', 'name', 'surname']);
	}

	initDatePicker(): void {
		setTimeout(() => {
			this.datePicker = flatpickr('#registrationDatePicker', {
				dateFormat: 'd/m/Y H:i',
				enableTime: true,
				mode: 'single',
				time_24hr: true,
				// locale: Italian,
				weekNumbers: true,
				onChange: (selectedDates) => {
					if (selectedDates && selectedDates.length > 0) {
						this.user.registrationDate = selectedDates[0].valueOf();
					}
				}
			});

		}, 200);
	}

	initJsonEditor(): void {
		const interval = setInterval(() => {
			if (document.getElementById('json_editor_selector')) {
				this.jsonEditor = new JSONEditor(document.getElementById('json_editor_selector'), {
					mode: 'code',
					language: 'en',
					onChange: () => {
						try {
							this.user.attributes = this.jsonEditor.get();
						} catch (e) {
							console.warn('Invalid JSON');
						}
					}
				}, this.user.attributes);
				clearInterval(interval);
			}
		}, 50);
	}

	sortUserClients(): void {
		this.user.userClients = this.user.userClients.sort((a, b) => {
			if (a.client.name < b.client.name) {
				return -1;
			}
			if (a.client.name > b.client.name) {
				return 1;
			}

			return 0;
		});
	}

	buildMunicipalities(): void {
		if (this.user.municipality && this.user.municipality.province !== '') {
			this.updateMunicipality(new MatSelectChange(null, this.user.municipality.province));
		}
	}

	goBack(): void {
		this.router.navigateByUrl('/admin/users').then(() => {
		});
	}

	save(): void {
		const processedClients = new Map();
		const duplicateClients = [];
		const emptyClients = [];
		const emptyRoles = [];

		for (const userClient of this.user.userClients) {
			if (processedClients.has(userClient.client.id)) {
				duplicateClients.push('userClients' + userClient.client.id);
			} else {
				processedClients.set(userClient.client.id, userClient);
			}

			if (userClient.client.id === 0) {
				emptyClients.push('userClients' + userClient.client.id);
			}
		}

		for (const userClient of this.user.userClients) {
			if (userClient.role === 'undefined') {
				emptyRoles.push('userClients' + userClient.client.id + userClient.role);
			}
		}

		if (duplicateClients.length > 0) {
			this.error.setFields(duplicateClients, 'The selected clients list contains duplicate elements.');
			this.notificationsService.error('Save user', 'Unable to save user. The selected clients list contains duplicate elements.');
		} else if (emptyClients.length > 0) {
			this.error.setFields(emptyClients, 'The selected clients list contains undefined clients.');
			this.notificationsService.error('Save user', 'Unable to save user. The selected clients list contains undefined clients.');
		} else if (emptyRoles.length > 0) {
			this.error.setFields(emptyRoles, 'The selected clients list contains undefined roles.');
			this.notificationsService.error('Save user', 'Unable to save user. The selected clients list contains undefined roles.');
		} else {
			this.userService.save(this.user).subscribe(
				res => {
					this.notificationsService.success('Save user', 'User saved successfully.');
					setTimeout(() => {
						this.router.navigateByUrl('/admin/users/' + res).then(() => {
							this.init();
						});
					}, 1000);
				},
				error => {
					this.error.setFields(error.errorFields, error.message);
					this.notificationsService.error('Save user', 'Unable to save user.');
				});
		}
	}

	delete(): void {
		const dialog = this.deleteDialog.open(
			DeleteUserComponent, {
				width: '500px'
			}
		);

		dialog.afterClosed().subscribe(res => {
			if (res && res === true) {
				this.userService.delete(this.user.id).subscribe(
					deleteResult => {
						if (deleteResult) {
							this.notificationsService.success('Delete user', 'User removed successfully.');

							setTimeout(() => {
								this.goBack();
							}, 1000);

						} else {
							this.notificationsService.error('Delete user', 'Unable to remove user.');
						}
					},
					error => {
						this.error.setFields(error.errorFields, error.message);
						this.notificationsService.error('Delete user', 'Unable to remove user');
					});
			}
		});
	}

	removeUserClient(clientId: number, role: string): void {
		const clientToRemove = this.user.userClients.filter(c => c.client.id === clientId && c.role === role)[0];
		this.user.userClients.splice(this.user.userClients.indexOf(clientToRemove), 1);

		if (this.user.userClients.length === 0) {
			this.addUserClient();
		}
	}

	addUserClient(): void {
		this.user.userClients.push(new UserClient({role: 'undefined', client: {id: 0}}));
	}

	openChangePasswordDialog(): void {
		this.changePasswordDialog.open(
			ChangePasswordComponent, {
				width: '500px',
				data: {
					adminFlow: true,
					email: this.user.email
				}
			}
		);
	}

	setPassword(password: string): void {
		this.user.password = password;
		this.error.reset('password');
	}

	setPasswordConfirm(password: string): void {
		this.user.passwordConfirm = password;
		this.error.reset('passwordConfirm');
	}

	getRegistrationDate(): string {
		return flatpickr.formatDate(new Date(this.user.registrationDate), 'd/m/Y H:i');
	}

	updateMunicipality(event: MatSelectChange): void {
		const province = event.value;

		if (province !== '') {
			this.municipalityService.getMunicipalities(province).subscribe(res => {
				this.municipalities = res;
			});
		}
	}
}
