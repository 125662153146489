<app-page [displayMenuIcon]="true" [displayAdditionalIcons]="true">
	<mat-card class="spacer">
		<mat-card-content>
			This section allows you to manage the CLIENTS that use the identity provider, through the four basic CRUD
			functionalities (Create, Read, Update, Delete).
		</mat-card-content>
	</mat-card>

	<mat-card class="spacer">
		<div class="table-filters">
			<div class="table-filters-title">Filters</div>
			<div class="table-filters-container">
				<mat-form-field appearance="fill">
					<mat-label>Client name</mat-label>
					<input matInput name="name" type="text" autocomplete="off" (focusout)="search()"
							 (keydown.enter)="search()" [ngModel]="getSearchFieldValue('name')"
							 (ngModelChange)="updateSearchField('name', $event)"/>
				</mat-form-field>
				<mat-form-field appearance="fill">
					<mat-label>Client ID</mat-label>
					<input matInput name="name" type="text" autocomplete="off" (focusout)="search()"
							 (keydown.enter)="search()" [ngModel]="getSearchFieldValue('clientId')"
							 (ngModelChange)="updateSearchField('clientId', $event)"/>
				</mat-form-field>
			</div>
		</div>
	</mat-card>

	<mat-card>
		<app-table [configuration]="tableConfiguration" [verifyEditActionFunction]="verifyClient" [verifyDeleteActionFunction]="verifyClient"
					  (pageSize)="updatePageSize()" (sort)="updateSortField($event)" (pagination)="updatePagination($event)"
					  (edit)="edit($event)" (delete)="delete($event)" (custom)="editUserAttributes($event)"></app-table>
	</mat-card>
</app-page>
