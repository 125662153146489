<mat-nav-list>
  <span *ngFor="let m of menu">
    <mat-list-item (click)="m.displayChildrenStatus = !m.displayChildrenStatus">
      <mat-icon mat-list-icon>{{m.icon}}</mat-icon>
      <a style="width: 100%;" *ngIf="m.url" mat-line routerLink="{{m.url}}">{{m.name}}</a>
      <a style="width: 100%;" *ngIf="m.url === null" mat-line>{{m.name}}</a>
      <mat-icon *ngIf="m.children" class="menu-button" [ngClass]="{'rotated' : m.displayChildrenStatus}">expand_more</mat-icon>
    </mat-list-item>

    <div *ngIf="m.children" class="submenu" [ngClass]="{'expanded' : m.displayChildrenStatus}" >
      <mat-list-item *ngFor="let c of m.children">
        <mat-icon mat-list-icon>{{c.icon}}</mat-icon>
        <a mat-line (click)="gotoLink(c.url)">{{c.name}}</a>
      </mat-list-item>
    </div>
  </span>

  <div class="bottom-menu-item">
    <mat-list-item>
      <mat-icon mat-list-icon>account_circle</mat-icon>
      <a mat-line class="bottom-link" routerLink="/profile/idp">Profile</a>
    </mat-list-item>
    <mat-list-item>
      <mat-icon mat-list-icon>logout</mat-icon>
      <a mat-line class="bottom-link" routerLink="/logout/idp">Logout</a>
    </mat-list-item>
  </div>
</mat-nav-list>
