import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map} from 'rxjs/operators';
import {NotificationsService} from 'angular2-notifications';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private router: Router, private authService: AuthService, private notificationsService: NotificationsService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

		if (AuthService.hasToken()) {
			return this.authService.verifyAdminOrReaderToken().pipe(
				map(res => {
					if (res) {
						return true;
					} else {
						AuthService.removeToken();
						AuthService.removeUser();

						this.router.navigateByUrl('/login/idp').then(() => {
							setTimeout(() => {
								this.notificationsService.error('Login error', 'Invalid token.');
							}, 1000);

							return false;
						});
					}
				}));
		} else {
			this.router.navigateByUrl('/login/idp').then(() => {
				return false;
			});
		}
	}
}
