<app-page [displayMenuIcon]="true" [displayAdditionalIcons]="true">
	<mat-card>
		<mat-card-title class="form">
			EDIT USER ATTRIBUTES
			<ng-container *ngIf="client">
				OF {{client.name}}
			</ng-container>
		</mat-card-title>
		<mat-card-content *ngIf="true">
			<div class="edit-container">
				<form autocomplete="off" (keydown.enter)="$event.preventDefault()">
					<mat-accordion multi class="content-detail">
						<mat-expansion-panel togglePosition="before" *ngFor="let elem of userAttributes; index as i">
							<mat-expansion-panel-header>
								<mat-panel-title>Attribute {{i + 1}}: {{elem.name}}</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="delete-action" title="Delete attribute" (click)="deleteAttribute($event, i)">delete</mat-icon>

									<mat-icon class="add-action"
												 title="Add new attribute" (click)="addAttribute($event, i)">add
									</mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="row">
								<div class="input-container grid-col-5">
									<mat-form-field appearance="fill">
										<mat-label>Name</mat-label>
										<input matInput type="text" [(ngModel)]="elem.name" autocomplete="off" [ngModelOptions]="{standalone: true}"/>
									</mat-form-field>
								</div>
								<div class="input-container grid-col-4">
									<mat-form-field appearance="fill">
										<mat-label>Type</mat-label>
										<mat-select [(ngModel)]="elem.type" (selectionChange)="verifyOptions(i)" [ngModelOptions]="{standalone: true}">
											<mat-option value="SHORT_TEXT">SHORT TEXT</mat-option>
											<mat-option value="LONG_TEXT">LONG TEXT</mat-option>
											<mat-option value="NUMBER">NUMBER</mat-option>
											<mat-option value="RADIO">RADIO</mat-option>
											<mat-option value="CHECKBOX">CHECKBOX</mat-option>
											<mat-option value="SELECT">SELECT</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="input-container grid-col-1">
									<mat-form-field appearance="fill">
										<mat-label>Required</mat-label>
										<mat-select [(ngModel)]="elem.required" [ngModelOptions]="{standalone: true}">
											<mat-option [value]="true">Yes</mat-option>
											<mat-option [value]="false">No</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>

							<div class="row">
								<div class="input-container grid-col-5">
									<mat-form-field appearance="fill">
										<mat-label>Label (IT)</mat-label>
										<input matInput type="text" [(ngModel)]="elem.labels['IT']" autocomplete="off" [ngModelOptions]="{standalone: true}"/>
									</mat-form-field>
								</div>
								<div class="input-container grid-col-5">
									<mat-form-field appearance="fill">
										<mat-label>Label (EN)</mat-label>
										<input matInput type="text" [(ngModel)]="elem.labels['EN']" autocomplete="off" [ngModelOptions]="{standalone: true}"/>
									</mat-form-field>
								</div>
							</div>

							<div class="row">
								<div class="input-container grid-col-5">
									<mat-form-field appearance="fill">
										<mat-label>Description (IT)</mat-label>
										<textarea matInput rows="3" [(ngModel)]="elem.descriptions['IT']" autocomplete="off" [ngModelOptions]="{standalone: true}"></textarea>
									</mat-form-field>
								</div>
								<div class="input-container grid-col-5">
									<mat-form-field appearance="fill">
										<mat-label>Description (EN)</mat-label>
										<textarea matInput rows="3" [(ngModel)]="elem.descriptions['EN']" autocomplete="off" [ngModelOptions]="{standalone: true}"></textarea>
									</mat-form-field>
								</div>
							</div>

							<ng-container *ngIf="elem.type === 'RADIO' || elem.type === 'CHECKBOX' || elem.type === 'SELECT'">
								<div class="row">
									<div class="input-container grid-col-10 options-title">
										<h4 class="form">Attribute options</h4>
									</div>
								</div>

								<div class="row" *ngFor="let option of elem.options; index as j">
									<div class="input-container grid-col-3">
										<mat-form-field appearance="fill">
											<mat-label>Option {{j + 1}} Value</mat-label>
											<input matInput type="text" [(ngModel)]="option.value" autocomplete="off" [ngModelOptions]="{standalone: true}"/>
										</mat-form-field>
									</div>
									<div class="input-container grid-col-3">
										<mat-form-field appearance="fill">
											<mat-label>Label (IT)</mat-label>
											<input matInput type="text" [(ngModel)]="option.labels['IT']" autocomplete="off" [ngModelOptions]="{standalone: true}"/>
										</mat-form-field>
									</div>
									<div class="input-container grid-col-3">
										<mat-form-field appearance="fill">
											<mat-label>Label (EN)</mat-label>
											<input matInput type="text" [(ngModel)]="option.labels['EN']" autocomplete="off" [ngModelOptions]="{standalone: true}"/>
										</mat-form-field>
									</div>
									<div class="input-container grid-col-1 options-actions">
										<button mat-icon-button title="Add new option" (click)="addOption($event, i, j)">
											<mat-icon>add</mat-icon>
										</button>
										<button mat-icon-button title="Delete option" (click)="deleteOption($event, i, j)">
											<mat-icon>delete</mat-icon>
										</button>
									</div>
								</div>
							</ng-container>

						</mat-expansion-panel>
					</mat-accordion>
				</form>
				<div class="edit-actions-container">
					<button mat-raised-button color="primary" class="edit-action" (click)="goBack()">BACK TO LIST</button>
					<button mat-raised-button color="primary" class="edit-action" (click)="save()">SAVE</button>
					<button mat-raised-button color="warn" class="edit-action" (click)="delete()">DELETE ALL</button>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</app-page>
