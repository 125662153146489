<div class="page-container" [class.is-mobile]="mobileQuery.matches">
	<app-header [mobileQuery]="mobileQuery" [sideNav]="sideNav"
					[displayMenuIcon]="displayMenuIcon" [logo]="logo"
					[backgroundColor]="headerBackgroundColor" [textColor]="headerTextColor"
					[displayAdditionalIcons]="displayAdditionalIcons" class="mat-elevation-z6">
	</app-header>
	<app-loader [overlay]="true"></app-loader>

	<mat-sidenav-container class="sidenav-container" [style.margin-top.px]="0">
		<!--<mat-sidenav #sideNav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
						 fixedTopGap="56">-->
		<mat-sidenav #sideNav [mode]="'over'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
			<app-menu></app-menu>
		</mat-sidenav>

		<mat-sidenav-content>
			<div class="main-container">
				<div class="main-content main-content-{{width}}">
					<ng-content></ng-content>
				</div>
				<app-footer></app-footer>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>

	<simple-notifications [options]="notificationOptions"></simple-notifications>
</div>
