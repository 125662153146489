import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeleteUserAttributesComponent} from '../../../dialogs/admin/delete-user-attributes/delete-user-attributes.component';
import {ClientService} from '../../../../services/client.service';
import {NotificationsService} from 'angular2-notifications';
import {MatDialog} from '@angular/material/dialog';
import {FormError} from '../../../../model/form-error.model';
import {Client} from '../../../../model/client.model';

@Component({
	selector: 'app-user-attributes-edit-admin',
	templateUrl: './user-attributes-edit-admin.component.html',
	styleUrls: ['./user-attributes-edit-admin.component.scss']
})
export class UserAttributesEditAdminComponent implements OnInit {

	id: number;
	client: Client;
	userAttributes: any[];

	error: FormError;

	constructor(
		private clientService: ClientService,
		private notificationsService: NotificationsService,
		private route: ActivatedRoute,
		private router: Router,
		private deleteDialog: MatDialog) {
	}

	ngOnInit(): void {
		this.init();
	}

	init(): void {
		this.error = new FormError(['clientId']);

		this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
		this.clientService.getUserAttributes(this.id).subscribe(res => {
			this.userAttributes = res;

			if (this.userAttributes === null || this.userAttributes.length === 0) {
				this.userAttributes = [];
				this.addAttribute(new MouseEvent(''), 0);
			}
		});
		this.clientService.getClient(this.id).subscribe(res => {
			this.client = res;
		});
	}

	goBack(): void {
		this.router.navigateByUrl('/admin/clients').then(() => {
		});
	}

	save(): void {
		this.clientService.saveUserAttributes(this.id, this.userAttributes).subscribe(
			res => {
				this.notificationsService.success('Save user attributes', 'User attributes saved successfully.');
				setTimeout(() => {
					this.router.navigateByUrl('/admin/clients/' + res + '/user-attributes').then(() => {
						this.init();
					});
				}, 1000);
			},
			error => {
				this.error.setFields(error.errorFields, error.message);
				this.notificationsService.error('Save user attributes', 'Unable to save user attributes. ' + error.message);
			});
	}

	delete(): void {
		const dialog = this.deleteDialog.open(
			DeleteUserAttributesComponent, {
				width: '500px'
			}
		);

		dialog.afterClosed().subscribe(res => {
			if (res && res === true) {
				this.clientService.deleteUserAttributes(this.id).subscribe(
					deleteResult => {
						if (deleteResult) {
							this.notificationsService.success('Delete user attributes', 'User attributes removed successfully.');

							setTimeout(() => {
								this.goBack();
							}, 1000);

						} else {
							this.notificationsService.error('Delete user attributes', 'Unable to remove user attributes.');
						}
					},
					error => {
						this.error.setFields(error.errorFields, error.message);
						this.notificationsService.error('Delete user attributes', 'Unable to remove user attributes.');
					});
			}
		});
	}

	addAttribute(event: MouseEvent, attributePosition: number): void {
		event.stopPropagation();

		this.userAttributes.splice(attributePosition + 1, 0, this.buildNewAttribute());
	}

	deleteAttribute(event: MouseEvent, attributePosition: number): void {
		event.stopPropagation();
		this.userAttributes.splice(attributePosition, 1);

		if (this.userAttributes.length === 0) {
			this.addAttribute(event, attributePosition);
		}
	}

	addOption(event: MouseEvent, attributePosition: number, optionPosition: number): void {
		event.stopPropagation();
		this.userAttributes[attributePosition].options.splice(optionPosition + 1, 0, this.buildNewOption());
	}

	deleteOption(event: MouseEvent, attributePosition: number, optionPosition: number): void {
		event.stopPropagation();
		this.userAttributes[attributePosition].options.splice(optionPosition, 1);

		if (this.userAttributes[attributePosition].options.length === 0) {
			this.addOption(event, attributePosition, optionPosition);
		}
	}

	verifyOptions(attributePosition: number): void {
		const type = this.userAttributes[attributePosition].type;
		if (type === 'RADIO' || type === 'CHECKBOX' || type === 'SELECT') {
			if (this.userAttributes[attributePosition].options === null || this.userAttributes[attributePosition].options.length === 0) {
				this.addOption(new MouseEvent(''), attributePosition, 0);
			}
		} else {
			this.userAttributes[attributePosition].options = [];
		}
	}

	private buildNewAttribute(): any {
		return {
			name: 'New user attribute',
			type: 'SHORT_TEXT',
			labels: {
				IT: '',
				EN: ''
			},
			descriptions: {
				IT: '',
				EN: ''
			},
			required: false,
			options: []
		};
	}

	private buildNewOption(): any {
		return {
			value: '',
			labels: {
				IT: '',
				EN: ''
			}
		};
	}

}
