<app-page [displayMenuIcon]="true" [displayAdditionalIcons]="true">
	<mat-card class="spacer">
		<mat-card-content>
			This section ANALYTICS allows you to view and filter the reports produced with the data managed by the Identity Provider.
		</mat-card-content>
	</mat-card>

	<mat-card class="spacer">
		<div class="table-filters">
			<div class="table-filters-title">Filters</div>
			<div class="table-filters-container">
				<mat-form-field appearance="fill">
					<mat-label>Client</mat-label>
					<mat-select (selectionChange)="search()" [ngModel]="getSearchFieldValue('clientId')"
									(ngModelChange)="updateSearchField('clientId', $event)">
						<mat-option>--- No Selection ---</mat-option>
						<mat-option *ngFor="let client of clients" value="{{client.id}}">{{client.name}}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="fill">
					<mat-label>Registration date</mat-label>
					<input matInput id="registrationDatePicker" name="registrationDatePicker"/>
					<mat-icon matSuffix style="width: 35px;">calendar_today</mat-icon>
				</mat-form-field>
			</div>
		</div>
	</mat-card>

	<mat-card>
		<highcharts-chart [Highcharts]="Highcharts" [options]="getChartOptions('countUsersByProvince')" *ngIf="isReady('countUsersByProvince')" style="width: 33% !important; display: block; float: left"></highcharts-chart>
		<highcharts-chart [Highcharts]="Highcharts" [options]="getChartOptions('countUsersByOrganizationType')" *ngIf="isReady('countUsersByOrganizationType')" style="width: 33% !important; display: block; float: left"></highcharts-chart>
		<highcharts-chart [Highcharts]="Highcharts" [options]="getChartOptions('countUsersByRole')" *ngIf="isReady('countUsersByRole')" style="width: 33% !important; display: block; margin-bottom: 80px"></highcharts-chart>

		<highcharts-chart [Highcharts]="Highcharts" [options]="getChartOptions('monthlyRegistrations')" *ngIf="isReady('monthlyRegistrations')" style="width: 100% !important; display: block; margin-bottom: 80px"></highcharts-chart>
		<highcharts-chart [Highcharts]="Highcharts" [options]="getChartOptions('dailyRegistrations')" *ngIf="isReady('dailyRegistrations')" style="width: 100% !important; display: block"></highcharts-chart>
	</mat-card>
</app-page>
