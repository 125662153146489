import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {
	public displayUI: BehaviorSubject<boolean>;

	constructor() {
		this.displayUI = new BehaviorSubject<boolean>(false);
	}

	public displayLoader(): void {
		this.displayUI.next(true);
	}

	public hideLoader(): void {
		this.displayUI.next(false);
	}
}
