import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {map, tap} from 'rxjs/operators';
import {Constants} from '../env/constants';
import {BehaviorSubject, Observable} from 'rxjs';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {LoginResponse} from '../model/login-response.model';
import {UserView} from '../model/user-view.model';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private static TOKEN_KEY = 'idp.token';
	private static USER_KEY = 'idp.user';

	public isLogged: BehaviorSubject<boolean>;

	constructor(private http: HttpService) {
		this.isLogged = new BehaviorSubject<boolean>(false);
	}

	public static hasToken(): boolean {
		return localStorage.getItem(AuthService.TOKEN_KEY) !== null;
	}

	public static getToken(): string {
		return localStorage.getItem(AuthService.TOKEN_KEY) || null;
	}

	public static setToken(token: string): void {
		localStorage.setItem(AuthService.TOKEN_KEY, token);
	}

	public static removeToken(): string {
		const res = localStorage.getItem(AuthService.TOKEN_KEY);
		localStorage.removeItem(AuthService.TOKEN_KEY);

		return res;
	}

	public static getUser(): UserView {
		return JSON.parse(localStorage.getItem(AuthService.USER_KEY)) || null;
	}

	public static setUser(user: UserView): void {
		localStorage.setItem(AuthService.USER_KEY, JSON.stringify(user));
	}

	public static removeUser(): UserView {
		const res = localStorage.getItem(AuthService.USER_KEY);
		localStorage.removeItem(AuthService.USER_KEY);

		return JSON.parse(res);
	}

	public verifyToken(): Observable<boolean> {
		return this.http.get(Constants.HMI.VERIFY_TOKEN).pipe(
			map(response => {

				if (response.valid) {
					return response.payload === true;
				} else {
					return false;
				}
			})
		);
	}

	public verifyAdminOrReaderToken(): Observable<boolean> {
		return this.http.get(Constants.ADMIN.VERIFY_ADMIN_OR_READER_TOKEN).pipe(
			map(response => {
				if (response.valid) {
					return response.payload === true;
				} else {
					return false;
				}
			})
		);
	}

	public verifyUserTokenAndClient(token: string, clientId: string): Observable<any> {
		return this.http.get(Constants.ADMIN.VERIFY_USER_TOKEN_AND_CLIENT, {token: token, clientId: clientId}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					return false;
				}
			})
		);
	}

	public decodeJwtToken(token: string): LoginResponse {
		const jwtPayload = jwtDecode<JwtPayload>(token);
		return new LoginResponse(jwtPayload['token'], jwtPayload['user']);
	}

	public loginWithCredentials(email: string, password: string, clientId: string, decodeJwtToken: boolean): Observable<any> {
		const body = {
			email: email,
			password: password
		};

		return this.http.post(Constants.HMI.POST_LOGIN, body, false, {clientId: clientId}).pipe(
			tap(response => {
				if (response.valid) {
					const lr = this.decodeJwtToken(response.payload);
					AuthService.setToken(lr.token);
					AuthService.setUser(lr.user);
				}
			}),
			map(response => {
				if (response.valid) {
					if (this.isLogged.value === false) {
						this.isLogged.next(true);
					}

					return decodeJwtToken ? this.decodeJwtToken(response.payload) : new LoginResponse(response.payload);
				} else {
					throw response.message;
				}
			})
		);
	}

	public loginWithToken(clientId: string, decodeJwtToken: boolean): Observable<LoginResponse> {
		return this.http.put(Constants.HMI.PUT_LOGIN, null, false, {clientId: clientId}).pipe(
			map(response => {
				if (response.valid) {
					if (this.isLogged.value === false) {
						this.isLogged.next(true);
					}

					const loginResponse = this.decodeJwtToken(response.payload);
					AuthService.setToken(loginResponse.token);
					AuthService.setUser(loginResponse.user);

					return decodeJwtToken ? loginResponse : new LoginResponse(response.payload);
				} else {
					throw response.message;
				}
			})
		);
	}

	public logout(clientId: string): Observable<string> {
		return this.http.put(Constants.HMI.PUT_LOGOUT, null, false, {clientId: clientId}).pipe(
			map(response => {
				if (response.valid) {
					if (this.isLogged.value === true) {
						this.isLogged.next(false);
					}

					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}
}
