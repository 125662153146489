import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {Constants} from '../env/constants';
import {map} from 'rxjs/operators';
import {OrganizationType} from '../model/organization-type.model';

@Injectable({
	providedIn: 'root'
})
export class OrganizationTypeService {

	constructor(private http: HttpService) {
	}

	public search(): Observable<OrganizationType[]> {
		return this.http.get(Constants.ADMIN.GET_ORGANIZATION_TYPES).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}
}
