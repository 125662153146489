<div class="table-header">
	<div class="table-header-title">{{configuration.name}}</div>
	<div class="table-header-container">
		<mat-form-field appearance="fill">
			<mat-label># per page</mat-label>
			<mat-select (selectionChange)="emitPageSizeEvent()" [(value)]="configuration.searchFilter.pageSize">
				<mat-option value="5">5</mat-option>
				<mat-option value="10">10</mat-option>
				<mat-option value="50">50</mat-option>
				<mat-option value="100">100</mat-option>
			</mat-select>
		</mat-form-field>
		<button mat-fab color="primary" title="Add" class="table-header-action"
				  (click)="emitEditEvent(0)">
			<mat-icon>add</mat-icon>
		</button>
		<button mat-fab color="accent" title="Download" class="table-header-action" style="margin-left: 20px;"
				  (click)="emitDownloadEvent(0)" [disabled]="configuration.disableDownload" *ngIf="configuration.displayDownload">
			<mat-icon>save_alt</mat-icon>
		</button>
	</div>
</div>

<div class="table-container">
	<table class="search-results" *ngIf="configuration.searchResults && configuration.searchResults.result.length > 0">
		<thead>
		<tr>
			<th *ngFor="let column of configuration.columns" [ngClass]="{'sortable': column.sortable}" (click)="emitUpdateSortFieldEvent(column)">
				{{column.label}}
				<mat-icon
					*ngIf="configuration.searchFilter.sortField === column.name && configuration.searchFilter.sortDirection === 'DESC'"
					style="vertical-align: middle;">arrow_drop_down
				</mat-icon>
				<mat-icon
					*ngIf="configuration.searchFilter.sortField === column.name && configuration.searchFilter.sortDirection === 'ASC'"
					style="vertical-align: middle;">arrow_drop_up
				</mat-icon>
			</th>
			<th class="actions">Actions</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let elem of configuration.searchResults.result">
			<td *ngFor="let column of configuration.columns">{{elem[column.name]}}</td>
			<td class="actions">
				<button mat-icon-button title="Edit" color="primary" (click)="emitEditEvent(elem.id)"
						  [disabled]="verifyEditActionFunction ? verifyEditActionFunction(elem) : false">
					<mat-icon>edit</mat-icon>
				</button>
				<button mat-icon-button title="{{configuration.actionColumn.label}}" color="primary" (click)="emitCustomEvent(elem.id)"
						  [disabled]="verifyCustomActionFunction ? verifyCustomActionFunction(elem) : false" *ngIf="configuration.actionColumn">
					<mat-icon>{{configuration.actionColumn.icon}}</mat-icon>
				</button>
				<button mat-icon-button title="Delete" color="accent" (click)="emitDeleteEvent(elem.id)"
						  [disabled]="verifyDeleteActionFunction ? verifyDeleteActionFunction(elem) : false">
					<mat-icon>delete</mat-icon>
				</button>
			</td>
		</tr>
		</tbody>
		<tfoot>
		<tr>
			<td [colSpan]="configuration.columns.length + 1">
				<div class="table-footer-container">
					<span>page {{configuration.searchFilter.pageNumber}} of {{configuration.searchResults.totalPages}}
						({{configuration.searchResults.totalResults}} elements)</span>

					<div class="table-footer-pagination">
						<button mat-icon-button
								  *ngFor="let p of configuration.searchResults.paginationElements"
								  [ngClass]="{'active': p.page === configuration.searchFilter.pageNumber && p.type !== 'icon'}"
								  (click)="emitUpdatePaginationEvent(p.page)">
							<span *ngIf="!p.type">{{p.label}}</span>
							<mat-icon *ngIf="p.type && p.type === 'icon'">{{p.label}}</mat-icon>
						</button>
					</div>
				</div>
			</td>
		</tr>
		</tfoot>
	</table>
	<div *ngIf="configuration.searchResults && configuration.searchResults.result.length == 0">
		No record available.
	</div>
</div>
