import {UserView} from './user-view.model';

export class LoginResponse {
	token: string;
	user: UserView;

	constructor(token: string, user?: UserView) {
		this.token = token;
		this.user = user;
	}
}
