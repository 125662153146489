<app-page [displayMenuIcon]="false" [logo]="clientUI.logo" [headerBackgroundColor]="clientUI.backgroundColor" [headerTextColor]="clientUI.textColor" *ngIf="clientUI">
	<mat-card>
		<mat-card-title class="form">
			{{ getLabel('template.editProfile') }}
		</mat-card-title>
		<mat-card-content>
			<form autocomplete="off" (keydown.enter)="$event.preventDefault()">

				<div class="row">
					<div class="grid-col-10">
						<h4 class="form">{{ getLabel('template.personalInfo') }}</h4>
					</div>
				</div>
				<div class="row">
					<div class="input-container grid-col-5">
						<mat-form-field appearance="fill">
							<mat-label>{{ getLabel('template.form.name') }}</mat-label>
							<input matInput name="name" type="text" [(ngModel)]="user.name" autocomplete="off"/>
							<mat-icon matSuffix style="width: 35px;"></mat-icon>
						</mat-form-field>
					</div>

					<div class="input-container grid-col-5">
						<mat-form-field appearance="fill">
							<mat-label>{{ getLabel('template.form.surname') }}</mat-label>
							<input matInput name="surname" type="text" [(ngModel)]="user.surname" autocomplete="off"/>
							<mat-icon matSuffix style="width: 35px;"></mat-icon>
						</mat-form-field>
					</div>
				</div>

				<div class="row">
					<div class="input-container grid-col-5">
						<mat-form-field appearance="fill">
							<mat-label>{{ getLabel('template.form.address') }}</mat-label>
							<input matInput name="address" type="text" [(ngModel)]="user.address" autocomplete="off"/>
							<mat-icon matSuffix style="width: 35px;"></mat-icon>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="input-container grid-col-5">
						<mat-form-field appearance="fill">
							<mat-label>{{ getLabel('template.form.organization') }}</mat-label>
							<input matInput name="organization" type="text" [(ngModel)]="user.organization"
									 autocomplete="none"/>
							<mat-icon matSuffix style="width: 35px;"></mat-icon>
						</mat-form-field>
					</div>

					<div class="input-container grid-col-3">
						<mat-form-field appearance="fill">
							<mat-label>{{ getLabel('template.form.province') }}</mat-label>
							<mat-select name="province" [(ngModel)]="user.municipality.province" (selectionChange)="updateMunicipality($event)" *ngIf="provinces">
								<mat-option [value]="''">{{ getLabel('template.form.choose') }}</mat-option>
								<mat-option *ngFor="let p of provinces" [value]="p.province">{{p.province}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="input-container grid-col-2">
						<mat-form-field appearance="fill">
							<mat-label>{{ getLabel('template.form.municipality') }}</mat-label>
							<mat-select name="municipality" [(ngModel)]="user.municipality.name">
								<mat-option [value]="''">{{ getLabel('template.form.choose') }}</mat-option>
								<mat-option *ngFor="let m of municipalities" [value]="m.name">{{m.name}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>

				<div class="row">
					<div class="input-container grid-col-3">
						<mat-form-field appearance="fill">
							<mat-label>{{ getLabel('template.form.organizationType') }}</mat-label>
							<mat-select name="organizationType" [(ngModel)]="user.organizationType.id">
								<mat-option *ngFor="let ot of organizationTypes" [value]="ot.id">{{ot.name}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="input-container grid-col-2">
						<mat-form-field appearance="fill">
							<mat-label>{{ getLabel('template.form.phone') }}</mat-label>
							<input matInput name="phone" type="tel" [(ngModel)]="user.phone" autocomplete="none"/>
							<mat-icon matSuffix style="width: 35px;"></mat-icon>
						</mat-form-field>
					</div>
				</div>

				<div class="row">
					<div class="grid-col-10">
						<h4 class="form">{{ getLabel('template.form.credential') }}</h4>
					</div>
				</div>
				<div class="row">
					<div class="input-container grid-col-5">
						<mat-form-field appearance="fill">
							<mat-label>E-mail</mat-label>
							<input matInput name="email" type="email" [(ngModel)]="user.email" autocomplete="none"/>
							<mat-icon matSuffix style="width: 35px;"></mat-icon>
							<mat-hint>{{ getLabel('template.form.email.hint') }}</mat-hint>
						</mat-form-field>
					</div>
					<div class="input-container grid-col-5">
						<app-password label="Password" name="password" value="********" [readonly]="true" [hideAction]="true"
										  (click)="openChangePasswordDialog()" ù
										  style="cursor: pointer; padding-right: 20px;"
										  hint="{{ getLabel('template.form.password.hint') }}"></app-password>
					</div>
				</div>

				<div class="row">
					<div class="grid-col-10">
						<h4 class="form">{{ getLabel('template.form.otherInformation') }}</h4>
					</div>
				</div>

				<div class="row" *ngIf="client.userAttributes !== null && client.userAttributes.length > 0">
					<div class="input-container grid-col-5" *ngFor="let attribute of client.userAttributes">
						<ng-container [ngSwitch]="attribute.type">
							<ng-container *ngSwitchCase="'SHORT_TEXT'">
								<mat-form-field appearance="fill">
									<mat-label>{{attribute.labels[language]}} {{attribute.required ? '*' : ''}}</mat-label>
									<input matInput name="{{attribute.name}}" type="text" [(ngModel)]="user.attributes[attribute.name]"
											 autocomplete="none"/>
									<mat-hint [innerHTML]="attribute.descriptions[language]"></mat-hint>
								</mat-form-field>
							</ng-container>
							<ng-container *ngSwitchCase="'LONG_TEXT'">
								<mat-form-field appearance="fill">
									<mat-label>{{attribute.labels[language]}} {{attribute.required ? '*' : ''}}</mat-label>
									<textarea matInput name="{{attribute.name}}" rows="3" [(ngModel)]="user.attributes[attribute.name]"
												 autocomplete="none"></textarea>
									<mat-hint [innerHTML]="attribute.descriptions[language]"></mat-hint>
								</mat-form-field>
							</ng-container>
							<ng-container *ngSwitchCase="'NUMBER'">
								<mat-form-field appearance="fill">
									<mat-label>{{attribute.labels[language]}} {{attribute.required ? '*' : ''}}</mat-label>
									<input matInput name="{{attribute.name}}" type="number" [(ngModel)]="user.attributes[attribute.name]"
											 autocomplete="none"/>
									<mat-hint [innerHTML]="attribute.descriptions[language]"></mat-hint>
								</mat-form-field>
							</ng-container>
							<ng-container *ngSwitchCase="'RADIO'">
								<mat-label>{{attribute.labels[language] | uppercase}} {{attribute.required ? '*' : ''}}</mat-label>
								<mat-radio-group name="{{attribute.name}}" [(ngModel)]="user.attributes[attribute.name]" style="margin: 10px 0">
									<mat-radio-button *ngFor="let option of attribute.options" name="{{attribute.name}}" value="{{option.value}}" style="margin-right: 20px;">
										{{option.labels[language]}}</mat-radio-button>
								</mat-radio-group>
								<mat-hint [innerHTML]="attribute.descriptions[language]" style="font-size: 75%;"></mat-hint>
							</ng-container>
							<ng-container *ngSwitchCase="'CHECKBOX'">
								<mat-label style="margin: 10px 0">{{attribute.labels[language] | uppercase}} {{attribute.required ? '*' : ''}}</mat-label>
								<mat-checkbox *ngFor="let option of attribute.options" name="{{attribute.name}}" value="{{option.value}}"
												  [checked]="getCheckboxCustomFieldValue(attribute.name, option.value)"
												  (change)="updateCheckboxCustomField($event, attribute.name, option.value)">{{option.labels[language]}}</mat-checkbox>
								<mat-hint [innerHTML]="attribute.descriptions[language]" style="font-size: 75%;"></mat-hint>
							</ng-container>
							<ng-container *ngSwitchCase="'SELECT'">
								<mat-form-field appearance="fill">
									<mat-label>{{attribute.labels[language]}} {{attribute.required ? '*' : ''}}</mat-label>
									<mat-select name="{{attribute.name}}" [(ngModel)]="user.attributes[attribute.name]">
										<mat-option *ngFor="let option of attribute.options" value="{{option.value}}">{{option.labels[language]}}</mat-option>
									</mat-select>
									<mat-hint [innerHTML]="attribute.descriptions[language]"></mat-hint>
								</mat-form-field>
							</ng-container>
						</ng-container>
					</div>
				</div>

				<div class="row">
					<div class="input-container grid-col-5">
						<mat-label>
							{{ getLabel('template.form.privacy.title') }} *<br/>
							{{ getLabel('template.form.privacy.part1') }}
							<a href="/#/privacy-policy" target="_blank">
								{{ getLabel('template.form.privacy.part2') }}
							</a>
							{{ getLabel('template.form.privacy.part3') }}
						</mat-label>
						<mat-radio-group name="privacyPolicy" [(ngModel)]="user.privacyPolicy" style="margin: 10px 0">
							<mat-radio-button name="privacyPolicy" [value]="true" style="margin-right: 20px;">{{ getLabel('template.form.privacy.yes') }}</mat-radio-button>
							<mat-radio-button name="privacyPolicy" [value]="false">{{ getLabel('template.form.privacy.no') }}</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>

				<div class="buttons-row">
					<div class="button-container grid-col-10">
						<div>
							<button class="action" mat-raised-button (click)="goBack()">{{ getLabel('template.form.btn.cancel') }}</button>
							<button class="action" mat-raised-button color="primary" (click)="updateProfile()"
									  [disabled]="disableActions"
									  [style]="'background:' + clientUI.backgroundColor + '; color: ' + clientUI.textColor">
								{{ getLabel('template.form.btn.update') }}
							</button>
						</div>
					</div>
				</div>
			</form>
		</mat-card-content>
	</mat-card>
</app-page>
