export class PaginationElement {
	page: number;
	label: string;
	type: string;


	constructor(page: number, label: string, type?: string) {
		this.page = page;
		this.label = label;
		this.type = type;
	}
}
