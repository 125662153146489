<mat-dialog-content>
  <h3>{{ getLabel('template.forgot') }}</h3>
  <p class="description">
	  {{ getLabel('template.forgot.message') }}
  </p>
  <form autocomplete="off" (keydown.enter)="$event.preventDefault()">
    <p>
      <mat-form-field appearance="fill">
        <mat-label>E-mail</mat-label>
        <input matInput name="email" type="email" [(ngModel)]="email" autocomplete="off"/>
        <mat-icon matSuffix style="width: 35px;"></mat-icon>
      </mat-form-field>
    </p>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button mat-raised-button mat-dialog-close>{{ getLabel('template.btn.close') }}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="email" [style]="'background:' + clientUI.backgroundColor + '; color: ' + clientUI.textColor">{{ getLabel('template.btn.reset') }}</button>
  </div>
</mat-dialog-actions>
