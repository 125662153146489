import {Component, OnInit} from '@angular/core';
import {Client} from '../../../../model/client.model';
import {ActivatedRoute} from '@angular/router';
import flatpickr from 'flatpickr';
import * as Highcharts from 'highcharts';
import {AnalyticsService} from '../../../../services/analytics.service';
import {SearchFilter} from '../../../../model/search-filter.model';

@Component({
	selector: 'app-analytics',
	templateUrl: './analytics.component.html',
	styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

	clients: Client[];

	flatpickr = flatpickr;
	datePicker: any;

	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: any = {};
	chartReady: any = {};

	searchFilter: SearchFilter;

	constructor(private route: ActivatedRoute, private analyticsService: AnalyticsService) {
	}

	ngOnInit(): void {
		this.clients = this.route.snapshot.data.clients;

		this.searchFilter = new SearchFilter();

		this.chartOptions['countUsersByProvince'] = null;
		this.chartOptions['countUsersByOrganizationType'] = null;
		this.chartOptions['countUsersByRole'] = null;
		this.chartOptions['monthlyRegistrations'] = null;
		this.chartOptions['dailyRegistrations'] = null;

		this.chartReady['countUsersByProvince'] = false;
		this.chartReady['countUsersByOrganizationType'] = false;
		this.chartReady['countUsersByRole'] = false;
		this.chartReady['monthlyRegistrations'] = false;
		this.chartReady['dailyRegistrations'] = false;

		this.initDatePicker();
		this.search();
	}

	search(): void {
		const filter = new SearchFilter();
		filter.fields = this.searchFilter.fields;

		filter.fields.chart = 'countUsersByProvince';
		this.analyticsService.search(filter).subscribe(chart => {
			this.chartOptions['countUsersByProvince'] = this.buildPieChartOptions(chart);
			this.chartReady['countUsersByProvince'] = true;
		});

		filter.fields.chart = 'countUsersByOrganizationType';
		this.analyticsService.search(filter).subscribe(chart => {
			this.chartOptions['countUsersByOrganizationType'] = this.buildPieChartOptions(chart);
			this.chartReady['countUsersByOrganizationType'] = true;
		});

		filter.fields.chart = 'countUsersByRole';
		this.analyticsService.search(filter).subscribe(chart => {
			this.chartOptions['countUsersByRole'] = this.buildPieChartOptions(chart);
			this.chartReady['countUsersByRole'] = true;
		});

		filter.fields.chart = 'monthlyRegistrations';
		this.analyticsService.search(filter).subscribe(chart => {
			this.chartOptions['monthlyRegistrations'] = this.buildDualAxisChartOptions(chart);
			this.chartReady['monthlyRegistrations'] = true;
		});

		filter.fields.chart = 'dailyRegistrations';
		this.analyticsService.search(filter).subscribe(chart => {
			this.chartOptions['dailyRegistrations'] = this.buildDualAxisChartOptions(chart);
			this.chartReady['dailyRegistrations'] = true;
		});
	}

	updateSearchField(field: string, value: any): void {
		if (field === 'clientId') {
			this.searchFilter.fields[field] = value === null ? null : Number(value);
		} else {
			this.searchFilter.fields[field] = value;
		}
	}

	getSearchFieldValue(field: string): any {
		const res = this.searchFilter.fields[field];
		return res ? res.toString() : res;
	}

	initDatePicker(): void {
		setTimeout(() => {
			this.datePicker = flatpickr('#registrationDatePicker', {
				dateFormat: 'd/m/Y',
				mode: 'range',
				// locale: Italian,
				weekNumbers: true,
				defaultDate: [this.getSearchFieldValue('registrationDateStart'), this.getSearchFieldValue('registrationDateEnd')],
				onChange: (selectedDates) => {
					if (selectedDates && selectedDates.length > 0) {
						const start = selectedDates[0].valueOf();
						const end = selectedDates.length === 2 ? selectedDates[1].valueOf() : null;

						this.updateSearchField('registrationDateStart', start);
						this.updateSearchField('registrationDateEnd', end);
					} else {
						this.updateSearchField('registrationDateStart', null);
						this.updateSearchField('registrationDateEnd', null);
					}

					this.search();
				}
			});

		}, 200);
	}

	getChartOptions(chartName: string): Highcharts.Options {
		return this.chartOptions[chartName];
	}

	isReady(chartName: string): boolean {
		return this.chartReady[chartName] === true;
	}

	private buildPieChartOptions(chart: any): any {
		return {
			chart: {
				type: chart.type
			},
			title: chart.title,
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					size: '50%',
					dataLabels: {
						enabled: true,
						format: '<b>{point.name}</b>: {point.percentage:.1f}%'
					}
				}
			},
			series: [{
				name: chart.series[0].name,
				data: chart.series[0].data.map(d => {
					const key = Object.keys(d)[0];

					return {
						name: key,
						y: d[key]
					};
				})
			}]
		};
	}

	private buildDualAxisChartOptions(chart: any): any {
		return {
			chart: {
				zoomType: 'xy'
			},
			title: chart.title,
			xAxis: [{
				categories: chart.series[0].data.map(d => {
					return Object.keys(d)[0];
				}),
				crosshair: true
			}],
			yAxis: [{
				title: {
					text: chart.yAxis[0].title
				}
			}, {
				opposite: true,
				title: {
					text: chart.yAxis[1].title
				}
			}],
			tooltip: {
				shared: true
			},
			series: [{
				name: chart.series[0].name,
				type: 'column',
				yAxis: 1,
				data: chart.series[0].data.map(d => {
					const key = Object.keys(d)[0];

					return {
						name: key,
						y: d[key]
					};
				})
			}, {
				name: chart.series[1].name,
				type: 'spline',
				data: chart.series[1].data.map(d => {
					const key = Object.keys(d)[0];

					return {
						name: key,
						y: d[key]
					};
				})
			}]
		};
	}
}
