export class HttpResponse {
	// Popolati dal client http
	valid: boolean;
	status: string;
	statusText: string;

	// Popolati dalla risposta ottenuta dal server
	code: string;
	message: string;
	payload: any;
	errorFields: string[];

	constructor(e?: any) {
		this.code = e && e.code !== undefined ? e.code : null;
		this.message = e && e.message !== undefined ? e.message : null;
		this.payload = e && e.payload !== undefined ? e.payload : null;
	}
}
