import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

	@Input() public name: string;
	@Input() public label: string;
	@Input() public readonly: boolean;
	@Input() public hideAction: boolean;
	@Input() public hint: string;
	@Input() public value: string;
	@Input() public class: any;
	@Input() public error: string;

	@Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

	public hidePassword: boolean;

	constructor() {
	}

	ngOnInit(): void {
		this.hidePassword = true;

		if (!this.value) {
			this.value = '';
		}
	}

	onChange(event): void {
		this.valueChange.emit(event.target.value);
	}
}
