export class FormError {
	private errors: Map<string, string>;

	constructor(fields: string[]) {
		this.errors = new Map<string, string>();

		fields.forEach(f => {
			this.reset(f);
		});
	}

	is(field: string): boolean {
		return this.errors.get(field) !== null;
	}

	get(field: string): string {
		return this.errors.get(field);
	}

	setField(field: string, value: string): void {
		this.errors.set(field, value);
	}

	setFields(fields: string[], value: string): void {
		if (fields) {
			fields.forEach(f => {
				this.errors.set(f, value);
			});
		}
	}

	reset(field: string): void {
		// console.log(field);
		this.errors.set(field, null);
	}
}
