import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Municipality} from '../model/municipality.model';
import {MunicipalityService} from '../services/municipality.service';

@Injectable({
	providedIn: 'root'
})
export class MunicipalityResolver implements Resolve<Municipality[]> {

	constructor(private municipalityService: MunicipalityService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Municipality[]> {
		return this.municipalityService.getProvinces();
	}
}
