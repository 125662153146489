export class SearchFilter {
	pageNumber: number;
	pageSize: string;
	sortField: string;
	sortDirection: string;
	fields: any;

	constructor() {
		this.fields = {};
	}
}
