import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {Municipality} from '../model/municipality.model';
import {Constants} from '../env/constants';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class MunicipalityService {

	constructor(private http: HttpService) {
	}

	public getProvinces(): Observable<Municipality[]> {
		return this.http.get(Constants.ADMIN.GET_PROVINCES).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}

	public getMunicipalities(province: string): Observable<Municipality[]> {
		return this.http.get(Constants.ADMIN.GET_MUNICIPALITIES, {province: province}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}
}
