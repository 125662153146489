import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {Role} from '../model/role.model';
import {Constants} from '../env/constants';
import {map} from 'rxjs/operators';
import {StatsResponse} from '../model/stats-response.model';

@Injectable({
	providedIn: 'root'
})
export class StatsService {

	constructor(private http: HttpService) {
	}

	public getStats(): Observable<StatsResponse> {
		return this.http.get(Constants.ADMIN.GET_STATS).pipe(
			map(response => {
				if (response.valid) {
					return new StatsResponse(response.payload.applications, response.payload.users, response.payload.sessions);
				} else {
					throw response.message;
				}
			})
		);
	}
}
