import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Constants} from '../env/constants';
import {map} from 'rxjs/operators';
import {HttpService} from './http.service';
import {Role} from '../model/role.model';
import {SearchFilter} from '../model/search-filter.model';
import {SearchResults} from '../model/search-results.model';

@Injectable({
	providedIn: 'root'
})
export class RoleService {

	constructor(private http: HttpService) {
	}

	public getRoleByName(name: string): Observable<Role> {
		return this.http.get(Constants.HMI.GET_ROLE, {roleName: name}).pipe(
			map(response => {
				if (response.valid) {
					return new Role(response.payload);
				} else {
					throw response.message;
				}
			})
		);
	}

	public getRole(roleId: number): Observable<Role> {
		return this.http.get(Constants.ADMIN.GET_ROLE, {roleId: roleId}).pipe(
			map(response => {
				if (response.valid) {
					return new Role(response.payload);
				} else {
					throw response.message;
				}
			})
		);
	}

	public search(filter: SearchFilter): Observable<SearchResults> {
		return this.http.put(Constants.ADMIN.PUT_SEARCH_ROLES, filter, false).pipe(
			map(response => {
				if (response.valid) {
					const res = new SearchResults(response.payload, 'Role');
					res.initPagination(filter.pageNumber);

					return res;
				} else {
					throw response.message;
				}
			})
		);
	}

	public save(role: Role): Observable<number> {
		if (role.id > 0) {
			return this.http.put(Constants.ADMIN.PUT_ROLE, role, false, {roleId: role.id}).pipe(
				map(response => {
					if (response.valid) {
						return response.payload;
					} else {
						throw {message: response.message, errorFields: response.errorFields};
					}
				})
			);
		} else {
			return this.http.post(Constants.ADMIN.POST_ROLE, role, false).pipe(
				map(response => {
					if (response.valid) {
						return response.payload;
					} else {
						throw {message: response.message, errorFields: response.errorFields};
					}
				})
			);
		}
	}

	public delete(roleId: number): Observable<boolean> {
		return this.http.delete(Constants.ADMIN.DELETE_ROLE, {roleId: roleId}).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw {message: response.message, errorFields: response.errorFields};
				}
			})
		);
	}
}
