<mat-toolbar color="primary" class="toolbar" *ngIf="mobileQuery && sideNav" [style]="'background:' + backgroundColor + '; color: ' + textColor">
  <button mat-icon-button (click)="sideNav.toggle()" *ngIf="displayMenuIcon">
    <mat-icon>menu</mat-icon>
  </button>
  <div style="display: flex; justify-content: center; align-items: center; height: 64px; width: 64px;" *ngIf="logo">
	  <img [src]="logo" style="max-width: 52px; max-height: 52px; margin-right: 10px;"  alt="Logo" (error)="hideLogo($event)"/>
  </div>
	<h1 class="app-name">
		Identity Provider
	</h1>
  <span class="spacer"></span>
  <span *ngIf="displayAdditionalIcons">
    <button mat-icon-button aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button>
  </span>
</mat-toolbar>
