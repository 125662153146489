import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColumnConfiguration, TableConfiguration} from '../../../model/table-configuration.model';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

	@Input() configuration: TableConfiguration;
	@Input() verifyEditActionFunction: (args: any) => boolean;
	@Input() verifyCustomActionFunction: (args: any) => boolean;
	@Input() verifyDeleteActionFunction: (args: any) => boolean;

	@Output() sort: EventEmitter<string> = new EventEmitter();
	@Output() pageSize: EventEmitter<number> = new EventEmitter();
	@Output() pagination: EventEmitter<number> = new EventEmitter();
	@Output() edit: EventEmitter<number> = new EventEmitter();
	@Output() download: EventEmitter<number> = new EventEmitter();
	@Output() custom: EventEmitter<number> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();

	constructor() {
	}

	ngOnInit(): void {
	}

	emitUpdateSortFieldEvent(column: ColumnConfiguration): void {
		if (column.sortable) {
			const field = column.name;
			if (this.configuration.searchFilter.sortField === field) {
				if (this.configuration.searchFilter.sortDirection === 'ASC') {
					this.configuration.searchFilter.sortDirection = 'DESC';
				} else {
					this.configuration.searchFilter.sortDirection = 'ASC';
				}
			} else {
				this.configuration.searchFilter.sortField = field;
				this.configuration.searchFilter.sortDirection = 'ASC';
			}

			this.sort.emit(field);
		}
	}

	emitPageSizeEvent(): void {
		this.configuration.searchFilter.pageNumber = 1;
		this.pageSize.emit();
	}

	emitUpdatePaginationEvent(pageNumber: number): void {
		this.configuration.searchFilter.pageNumber = pageNumber;
		this.pagination.emit(pageNumber);
	}

	emitEditEvent(id: number): void {
		this.edit.emit(id);
	}

	emitDownloadEvent(id: number): void {
		this.configuration.disableDownload = true;
		this.download.emit(0);
	}

	emitCustomEvent(id: number): void {
		this.custom.emit(id);
	}

	emitDeleteEvent(id: number): void {
		this.delete.emit(id);
	}
}
