export class Constants {
	private static PREFIX = '';
	private static HOST = '';
	// private static HOST = 'http://192.168.1.188:8989';

	public static HMI = {
		GET_CURRENT_USER: Constants.HOST + Constants.PREFIX + '/api/hmi/user',
		GET_USER: Constants.HOST + Constants.PREFIX + '/api/hmi/user/{{email}}',
		GET_CLIENT: Constants.HOST + Constants.PREFIX + '/api/hmi/client/{{clientId}}',
		GET_ROLE: Constants.HOST + Constants.PREFIX + '/api/hmi/role/{{roleName}}',
		VERIFY_TOKEN: Constants.HOST + Constants.PREFIX + '/api/hmi/isValid',
		POST_LOGIN: Constants.HOST + Constants.PREFIX + '/api/hmi/login/{{clientId}}',
		PUT_LOGIN: Constants.HOST + Constants.PREFIX + '/api/hmi/login/{{clientId}}',
		POST_SIGNUP: Constants.HOST + Constants.PREFIX + '/api/hmi/signup/{{clientId}}?l={{language}}',
		PUT_SIGNUP: Constants.HOST + Constants.PREFIX + '/api/hmi/signup/{{clientId}}?l={{language}}',
		PUT_UPDATE_USER: Constants.HOST + Constants.PREFIX + '/api/hmi/user/{{clientId}}?l={{language}}',
		PUT_LOGOUT: Constants.HOST + Constants.PREFIX + '/api/hmi/logout/{{clientId}}',
		POST_SEND_RESET_PASSWORD_EMAIL: Constants.HOST + Constants.PREFIX + '/api/hmi/user/sendResetPasswordEmail/{{email}}',
		POST_VALIDATE_EMAIL: Constants.HOST + Constants.PREFIX + '/api/hmi/user/validateEmail/{{validationToken}}',
		POST_RESET_PASSWORD: Constants.HOST + Constants.PREFIX + '/api/hmi/user/resetPassword/{{resetToken}}',
		PUT_UPDATE_PASSWORD: Constants.HOST + Constants.PREFIX + '/api/hmi/user/updatePassword',
		DELETE_USER_LOCALLY: Constants.HOST + Constants.PREFIX + '/api/hmi/user/{{clientId}}?l={{language}}',
		DELETE_USER_GLOBALLY: Constants.HOST + Constants.PREFIX + '/api/hmi/user?l={{language}}'
	};

	public static ADMIN = {
		VERIFY_ADMIN_OR_READER_TOKEN: Constants.HOST + Constants.PREFIX + '/api/admin/isValid',
		VERIFY_USER_TOKEN_AND_CLIENT: Constants.HOST + Constants.PREFIX + '/api/admin/isUserTokenValid/{{clientId}}/{{token}}',
		GET_STATS: Constants.HOST + Constants.PREFIX + '/api/admin/stats',
		PUT_SEARCH_ROLES: Constants.HOST + Constants.PREFIX + '/api/admin/roles',
		GET_ROLE: Constants.HOST + Constants.PREFIX + '/api/admin/roles/{{roleId}}',
		POST_ROLE: Constants.HOST + Constants.PREFIX + '/api/admin/roles',
		PUT_ROLE: Constants.HOST + Constants.PREFIX + '/api/admin/roles/{{roleId}}',
		DELETE_ROLE: Constants.HOST + Constants.PREFIX + '/api/admin/roles/{{roleId}}',
		PUT_SEARCH_CLIENTS: Constants.HOST + Constants.PREFIX + '/api/admin/clients',
		GET_CLIENT: Constants.HOST + Constants.PREFIX + '/api/admin/clients/{{id}}',
		GET_CLIENT_LOGO: Constants.HOST + Constants.PREFIX + '/api/admin/client/{{clientId}}/logo',
		GET_CLIENT_CREDENTIALS: Constants.HOST + Constants.PREFIX + '/api/admin/clients/credentials',
		GET_CLIENT_USER_ATTRIBUTES: Constants.HOST + Constants.PREFIX + '/api/admin/clients/{{id}}/user-attributes',
		POST_CLIENT: Constants.HOST + Constants.PREFIX + '/api/admin/clients',
		PUT_CLIENT: Constants.HOST + Constants.PREFIX + '/api/admin/clients/{{id}}',
		PUT_CLIENT_USER_ATTRIBUTES: Constants.HOST + Constants.PREFIX + '/api/admin/clients/{{id}}/user-attributes',
		DELETE_CLIENT: Constants.HOST + Constants.PREFIX + '/api/admin/clients/{{id}}',
		DELETE_CLIENT_LOGO: Constants.HOST + Constants.PREFIX + '/api/admin/client/{{clientId}}/logo',
		DELETE_CLIENT_USER_ATTRIBUTES: Constants.HOST + Constants.PREFIX + '/api/admin/clients/{{id}}/user-attributes',
		PUT_SEARCH_USERS: Constants.HOST + Constants.PREFIX + '/api/admin/users',
		GET_USER: Constants.HOST + Constants.PREFIX + '/api/admin/users/{{userId}}',
		POST_USER: Constants.HOST + Constants.PREFIX + '/api/admin/users',
		PUT_USER: Constants.HOST + Constants.PREFIX + '/api/admin/users/{{userId}}',
		DELETE_USER: Constants.HOST + Constants.PREFIX + '/api/admin/users/{{userId}}',
		PUT_UPDATE_PASSWORD: Constants.HOST + Constants.PREFIX + '/api/admin/users/updatePassword',
		PUT_EXPORT_USERS: Constants.HOST + Constants.PREFIX + '/api/admin/users/export',
		GET_ORGANIZATION_TYPES: Constants.HOST + Constants.PREFIX + '/api/admin/organizationTypes',
		GET_PROVINCES: Constants.HOST + Constants.PREFIX + '/api/admin/provinces',
		GET_MUNICIPALITIES: Constants.HOST + Constants.PREFIX + '/api/admin/provinces/{{province}}/municipality',
		PUT_CHART: Constants.HOST + Constants.PREFIX + '/api/charts'
	};

	public static STATUS = {
		GET_RUNTIME_INFO: Constants.HOST + Constants.PREFIX + '/api/status/runtimeInfo'
	};
}
