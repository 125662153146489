import {Client} from './client.model';

export class UserClient {
	role: string;
	client: Client;

	constructor(e?: any) {
		this.role = e && e.role !== undefined ? e.role : null;
		this.client = e && e.client !== undefined ? new Client(e.client) : null;
	}
}
