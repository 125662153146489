<app-page [displayMenuIcon]="true" [displayAdditionalIcons]="true">
	<mat-card>
		<mat-card-title class="form">
			EDIT USER
		</mat-card-title>
		<mat-card-content *ngIf="user">
			<div class="edit-container">
				<form autocomplete="off" (keydown.enter)="$event.preventDefault()">
					<div class="row">
						<div class="grid-col-10">
							<h4 class="form">Credential</h4>
						</div>
					</div>
					<div class="row">
						<div class="input-container grid-col-3">
							<mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': error.is('email')}">
								<mat-label>E-mail</mat-label>
								<input matInput name="email" type="email" [(ngModel)]="user.email" autocomplete="off" (keydown)="error.reset('email')" />
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
								<mat-hint class="mat-error">{{error.get('email')}}</mat-hint>
							</mat-form-field>
						</div>
						<div class="input-container grid-col-2">
							<mat-form-field appearance="fill">
								<mat-label>Verified</mat-label>
								<mat-select name="emailConfirmed" [(ngModel)]="user.emailConfirmed">
									<mat-option [value]="true">Yes</mat-option>
									<mat-option [value]="false">No</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="input-container grid-col-5">
							<app-password *ngIf="user.id > 0" label="Password" name="password" value="********" [readonly]="true" [hideAction]="true" (click)="openChangePasswordDialog()" ù
											  style="cursor: pointer; padding-right: 20px;" hint="Click here to modify your password"></app-password>
						</div>
					</div>

					<div class="row" *ngIf="user.id == null">
						<div class="input-container grid-col-5">
							<app-password label="Password" name="password" [value]="user.password" (valueChange)="setPassword($event)"
											  [class]="{'mat-form-field-invalid': error.is('password')}" [error]="error.get('password')"></app-password>
						</div>
						<div class="input-container grid-col-5">
							<app-password label="Password confirm" name="passwordConfirm" [value]="user.passwordConfirm" (valueChange)="setPasswordConfirm($event)"
											  [class]="{'mat-form-field-invalid': error.is('passwordConfirm')}" [error]="error.get('passwordConfirm')"></app-password>
						</div>
					</div>

					<div class="row">
						<div class="input-container grid-col-3">
							<mat-form-field appearance="fill">
								<mat-label>Registration date</mat-label>
								<input matInput id="registrationDatePicker" name="registrationDatePicker" [value]="getRegistrationDate()" />
								<mat-icon matSuffix style="width: 35px;">calendar_today</mat-icon>
							</mat-form-field>
						</div>
						<div class="input-container grid-col-2">
							<mat-form-field appearance="fill">
								<mat-label>Policy</mat-label>
								<mat-select name="privacyPolicy" [(ngModel)]="user.privacyPolicy">
									<mat-option [value]="true">Yes</mat-option>
									<mat-option [value]="false">No</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill">
								<mat-label>Blockchain ID</mat-label>
								<input matInput name="blockchainId" type="text" [(ngModel)]="user.blockchainId" autocomplete="off"/>
								<mat-icon matSuffix style="width: 35px;">account_balance_wallet</mat-icon>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="grid-col-10">
							<h4 class="form">Clients</h4>
						</div>
					</div>

					<ng-container *ngFor="let userClient of user.userClients; last as isLast">
						<div class="row">
							<div class="input-container grid-col-5">
								<mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': error.is('userClients' + userClient.client.id)}" (selectstart)="error.reset('userClients' + userClient.client.id)">
									<mat-label>Client</mat-label>
									<mat-select name="client" [(ngModel)]="userClient.client.id" [ngModelOptions]="{standalone: true}">
										<mat-option [value]="0">Choose...</mat-option>
										<mat-option *ngFor="let client of clients" [value]="client.id">{{client.name}}</mat-option>
									</mat-select>
									<mat-hint class="mat-error">{{error.get('userClients' + userClient.client.id)}}</mat-hint>
								</mat-form-field>
							</div>

							<div class="input-container grid-col-4">
								<mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': error.is('userClients' + userClient.client.id + userClient.role)}" (selectstart)="error.reset('userClients' + userClient.client.id + userClient.role)">
									<mat-label>Role</mat-label>
									<mat-select name="client" [(ngModel)]="userClient.role" [ngModelOptions]="{standalone: true}">
										<mat-option [value]="'undefined'">Choose...</mat-option>
										<mat-option *ngFor="let role of roles" [value]="role.name">{{role.name}}</mat-option>
									</mat-select>
									<mat-hint class="mat-error">{{error.get('userClients' + userClient.client.id + userClient.role)}}</mat-hint>
								</mat-form-field>
							</div>

							<div class="input-container grid-col-1">
								<div>
									<mat-icon class="delete-action" title="Delete relation" (click)="removeUserClient(userClient.client.id, userClient.role)">delete</mat-icon>
									<mat-icon *ngIf="isLast" class="add-action" title="Add new relation" (click)="addUserClient()">add</mat-icon>
								</div>
							</div>
						</div>
					</ng-container>

					<div class="row">
						<div class="grid-col-10">
							<h4 class="form">Personal info</h4>
						</div>
					</div>
					<div class="row">
						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': error.is('name')}">
								<mat-label>Name</mat-label>
								<input matInput name="name" type="text" [(ngModel)]="user.name" autocomplete="off"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
								<mat-hint class="mat-error">{{error.get('name')}}</mat-hint>
							</mat-form-field>
						</div>

						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': error.is('surname')}">
								<mat-label>Surname</mat-label>
								<input matInput name="surname" type="text" [(ngModel)]="user.surname" autocomplete="off"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
								<mat-hint class="mat-error">{{error.get('surname')}}</mat-hint>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill">
								<mat-label>Address</mat-label>
								<input matInput name="address" type="text" [(ngModel)]="user.address" autocomplete="off"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>

						<div class="input-container grid-col-3">
							<mat-form-field appearance="fill">
								<mat-label>Province</mat-label>
								<mat-select name="province" [(ngModel)]="user.municipality.province" (selectionChange)="updateMunicipality($event)">
									<mat-option [value]="''">Choose...</mat-option>
									<mat-option *ngFor="let p of provinces" [value]="p.province">{{p.province}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div class="input-container grid-col-2">
							<mat-form-field appearance="fill">
								<mat-label>Municipality</mat-label>
								<mat-select name="municipality" [(ngModel)]="user.municipality.name">
									<mat-option [value]="''">Choose...</mat-option>
									<mat-option *ngFor="let m of municipalities" [value]="m.name">{{m.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="input-container grid-col-5">
							<mat-form-field appearance="fill">
								<mat-label>Organization</mat-label>
								<input matInput name="organization" type="text" [(ngModel)]="user.organization" autocomplete="off"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>

						<div class="input-container grid-col-3">
							<mat-form-field appearance="fill">
								<mat-label>Organization Type</mat-label>
								<mat-select name="organizationType" [(ngModel)]="user.organizationType.id">
									<mat-option *ngFor="let ot of organizationTypes" [value]="ot.id">{{ot.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="input-container grid-col-2">
							<mat-form-field appearance="fill">
								<mat-label>Phone</mat-label>
								<input matInput name="phone" type="tel" [(ngModel)]="user.phone" autocomplete="off"/>
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="grid-col-10">
							<h4 class="form">Custom attributes</h4>
						</div>
					</div>
					<div class="row">
						<div class="input-container grid-col-10">
							<div id="json_editor_selector"></div>
						</div>
					</div>
				</form>
				<div class="edit-actions-container">
					<button mat-raised-button color="primary" class="edit-action" (click)="goBack()">BACK TO LIST</button>
					<button mat-raised-button color="primary" class="edit-action" (click)="save()">SAVE</button>
					<button *ngIf="user.id > 0" mat-raised-button color="warn" class="edit-action" (click)="delete()">DELETE</button>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</app-page>
