<app-page [displayMenuIcon]="true" [displayAdditionalIcons]="true">
	<mat-card>
		<mat-card-title class="form">
			EDIT ROLE
		</mat-card-title>
		<mat-card-content *ngIf="role">
			<div class="edit-container">
				<form autocomplete="off" (keydown.enter)="$event.preventDefault()">
					<div class="row">
						<div class="input-container grid-col-10">
							<mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': error.is('name')}">
								<mat-label>Name</mat-label>
								<input matInput name="name" type="text" [(ngModel)]="role.name" [disabled]="role.defaultRole" autocomplete="off" (keydown)="error.reset('name')" />
								<mat-icon matSuffix style="width: 35px;"></mat-icon>
								<mat-hint class="mat-error">{{error.get('name')}}</mat-hint>
							</mat-form-field>
						</div>
					</div>
				</form>
				<div class="edit-actions-container">
					<button mat-raised-button color="primary" class="edit-action" (click)="goBack()">BACK TO LIST</button>
					<button mat-raised-button color="primary" class="edit-action" [disabled]="role.defaultRole"
							  (click)="save()">SAVE
					</button>
					<button *ngIf="role.id > 0" mat-raised-button color="warn" class="edit-action"
							  [disabled]="role.defaultRole" (click)="delete()">DELETE
					</button>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</app-page>
