import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StatsResponse} from '../model/stats-response.model';
import {Constants} from '../env/constants';
import {map} from 'rxjs/operators';
import {HttpService} from './http.service';

@Injectable({
	providedIn: 'root'
})
export class StatusService {

	constructor(private http: HttpService) {
	}

	public getRuntimeInfo(): Observable<any> {
		return this.http.get(Constants.STATUS.GET_RUNTIME_INFO).pipe(
			map(response => {
				if (response.valid) {
					return response.payload;
				} else {
					throw response.message;
				}
			})
		);
	}
}
