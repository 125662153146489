import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

	email: string;

	clientUI: any;

	public language: string;
	public labels = {
		'IT': {
			'template.forgot': 'Password dimenticata',
			'template.forgot.message': 'Una volta inserito l\'indirizzo e-mail registrato, vi verranno inviate le istruzioni per reimpostare la password.',
			'template.btn.close': 'Chiudi',
			'template.btn.reset': 'Resetta la password'
		},
		'EN': {
			'template.forgot': 'Forgot password',
			'template.forgot.message': 'When you fill in your registered e-mail address, you will be sent instructions on how to reset your password.',
			'template.btn.close': 'Close',
			'template.btn.reset': 'Reset password'
		}
	};

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
		this.clientUI = data.clientUI;
		this.language = data && data.language || 'EN';
	}

	ngOnInit(): void {
		this.email = '';
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}
}
