import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {User} from '../../../../model/user.model';
import {NotificationsService} from 'angular2-notifications';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

	user: User;

	disableResetButton: boolean;

	public language: string;
	public labels = {
		'IT': {
			'template.reset': 'RESET DELLA PASSWORD',
			'template.btn.reset': 'Procedi',
			'notification.reset.title': 'Password reset',
			'notification.reset.message1': 'La password è stata modificata con successo.',
			'notification.reset.message2': 'Non è stato possibile modificare la password. Si prega di contattare l\'amministratore di sistema.'
		},
		'EN': {
			'template.reset': 'PASSWORD RESET',
			'template.btn.reset': 'Reset password',
			'notification.reset.title': 'Password reset',
			'notification.reset.message1': 'Your password was reset successfully.',
			'notification.reset.message2': 'Unable to reset your password. Please contact the system administrator.'
		}
	};

	constructor(
		private route: ActivatedRoute,
		private userService: UserService,
		private notificationsService: NotificationsService,
		private loaderService: LoaderService
	) {
	}

	ngOnInit(): void {
		this.user = new User();
		this.user.password = '';
		this.user.passwordConfirm = '';

		this.disableResetButton = false;

		this.language = this.route.snapshot.data.language;
	}

	resetPassword(): void {
		this.loaderService.displayLoader();

		const resetToken: string = this.route.snapshot.paramMap.get('resetToken');
		this.userService.resetPassword(this.user, resetToken).subscribe(res => {
			this.disableResetButton = true;
			this.loaderService.hideLoader();

			if (res) {
				this.notificationsService.success(this.getLabel('notification.reset.title'), this.getLabel('notification.reset.message1'));
			} else {
				this.notificationsService.error(this.getLabel('notification.reset.title'), this.getLabel('notification.reset.message2'));
			}
		});
	}

	setPassword(password): void {
		this.user.password = password;
	}

	setPasswordConfirm(password): void {
		this.user.passwordConfirm = password;
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}

}
