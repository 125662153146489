import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {HighchartsChartModule} from 'highcharts-angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

// Interceptors
import {AuthInterceptor} from './interceptors/auth.interceptor';

import {FileUploadModule} from '@iplab/ngx-file-upload';
import {ColorPickerModule} from 'ngx-color-picker';

// Layout components
import {PageComponent} from './components/layout/page/page.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {FooterComponent} from './components/layout/footer/footer.component';
import {LoaderComponent} from './components/layout/loader/loader.component';
import {MenuComponent} from './components/layout/menu/menu.component';

// Widgets
import {PasswordComponent} from './components/widgets/password/password.component';
import {TableComponent} from './components/widgets/table/table.component';

// Dialog components (HMI)
import {ForgotPasswordComponent} from './components/dialogs/hmi/forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './components/dialogs/hmi/change-password/change-password.component';

// Dialog components (ADMIN)
import {DeleteRoleComponent} from './components/dialogs/admin/delete-role/delete-role.component';
import {DeleteClientComponent} from './components/dialogs/admin/delete-client/delete-client.component';
import {DeleteUserComponent} from './components/dialogs/admin/delete-user/delete-user.component';

// Page components (HMI)
import {LoginComponent} from './components/pages/hmi/login/login.component';
import {SignupComponent} from './components/pages/hmi/signup/signup.component';
import {ValidateEmailComponent} from './components/pages/hmi/validate-email/validate-email.component';
import {ResetPasswordComponent} from './components/pages/hmi/reset-password/reset-password.component';
import {LogoutComponent} from './components/pages/hmi/logout/logout.component';
import {ProfileComponent} from './components/pages/hmi/profile/profile.component';
import {DeleteComponent} from './components/pages/hmi/delete/delete.component';
import {PrivacyPolicyComponent} from './components/pages/hmi/privacy-policy/privacy-policy.component';

// Page components (ADMIN)
import {LoginDispatcherComponent} from './components/pages/admin/login-dispatcher/login-dispatcher.component';
import {LogoutDispatcherComponent} from './components/pages/admin/logout-dispatcher/logout-dispatcher.component';
import {ProfileDispatcherComponent} from './components/pages/admin/profile-dispatcher/profile-dispatcher.component';
import {HomeComponent} from './components/pages/admin/home/home.component';
import {RolesAdminComponent} from './components/pages/admin/roles-admin/roles-admin.component';
import {RolesEditAdminComponent} from './components/pages/admin/roles-edit-admin/roles-edit-admin.component';
import {ClientsAdminComponent} from './components/pages/admin/clients-admin/clients-admin.component';
import {ClientsEditAdminComponent} from './components/pages/admin/clients-edit-admin/clients-edit-admin.component';
import {UserAttributesEditAdminComponent} from './components/pages/admin/user-attributes-edit-admin/user-attributes-edit-admin.component';
import {UsersAdminComponent} from './components/pages/admin/users-admin/users-admin.component';
import {UsersEditAdminComponent} from './components/pages/admin/users-edit-admin/users-edit-admin.component';
import {AnalyticsComponent} from './components/pages/admin/analytics/analytics.component';
import {DeleteUserAttributesComponent} from './components/dialogs/admin/delete-user-attributes/delete-user-attributes.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		PageComponent,
		HeaderComponent,
		FooterComponent,
		MenuComponent,
		LoaderComponent,
		LoginComponent,
		ForgotPasswordComponent,
		SignupComponent,
		ValidateEmailComponent,
		ResetPasswordComponent,
		LogoutComponent,
		ProfileComponent,
		ChangePasswordComponent,
		DeleteComponent,
		PasswordComponent,
		LoginDispatcherComponent,
		RolesAdminComponent,
		LogoutDispatcherComponent,
		ProfileDispatcherComponent,
		TableComponent,
		DeleteRoleComponent,
		RolesEditAdminComponent,
		ClientsAdminComponent,
		DeleteClientComponent,
		ClientsEditAdminComponent,
		UsersAdminComponent,
		UsersEditAdminComponent,
		DeleteUserComponent,
		PrivacyPolicyComponent,
		AnalyticsComponent,
		UserAttributesEditAdminComponent,
		DeleteUserAttributesComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		MatListModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		MatCardModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatDialogModule,
		MatGridListModule,
		MatChipsModule,
		MatAutocompleteModule,
		SimpleNotificationsModule.forRoot(),
		FileUploadModule,
		ColorPickerModule,
		MatRadioModule,
		HighchartsChartModule,
		MatExpansionModule,
		MatCheckboxModule
	],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
