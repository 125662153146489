import {Constants} from '../env/constants';

export class Client {
	id: number;
	name: string;
	scp: boolean;
	clientId: string;
	clientSecret: string;
	loginCallbackUrl: string;
	logoutCallbackUrl: string;
	profileCallbackUrl: string;

	logo: string;
	logoBase64: string;
	headerBackgroundColor: string;
	headerTextColor: string;

	userAttributes: any[];

	constructor(e?: any) {
		this.id = e && e.id !== undefined ? e.id : null;
		this.name = e && e.name !== undefined ? e.name : null;
		this.scp = e && e.scp !== undefined ? e.scp : false;
		this.clientId = e && e.clientId !== undefined ? e.clientId : null;
		this.clientSecret = e && e.clientSecret !== undefined ? e.clientSecret : null;
		this.loginCallbackUrl = e && e.loginCallbackUrl !== undefined ? e.loginCallbackUrl : null;
		this.logoutCallbackUrl = e && e.logoutCallbackUrl !== undefined ? e.logoutCallbackUrl : null;
		this.profileCallbackUrl = e && e.profileCallbackUrl !== undefined ? e.profileCallbackUrl : null;

		this.logo = this.clientId ? Constants.ADMIN.GET_CLIENT_LOGO.replace('{{clientId}}', this.clientId) : 'assets/logo/no-image.png';
		this.headerBackgroundColor = e && e.headerBackgroundColor !== undefined ? e.headerBackgroundColor : null;
		this.headerTextColor = e && e.headerTextColor !== undefined ? e.headerTextColor : null;

		this.userAttributes = e && e.userAttributes !== undefined ? e.userAttributes : null;
	}
}
