import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Client} from '../model/client.model';
import {ClientService} from '../services/client.service';
import {SearchFilter} from '../model/search-filter.model';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ClientResolver implements Resolve<Client[]> {

	private searchFilter = new SearchFilter();

	constructor(private clientService: ClientService) {
		this.searchFilter.pageNumber = 1;
		this.searchFilter.pageSize = '100';
		this.searchFilter.sortField = 'name';
		this.searchFilter.sortDirection = 'ASC';
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Client[]> {
		return this.clientService.search(this.searchFilter).pipe(
			map(res => {
				return res.result;
			})
		);
	}
}
