<app-page [displayMenuIcon]="false">
	<mat-card>
		<mat-card-title class="form">
			{{ getLabel('template.validation') }}
		</mat-card-title>
		<mat-card-content>
			<div class="message" *ngIf="displayOkMessage">
				{{ getLabel('template.validation.message1') }}
			</div>
			<div class="message" *ngIf="displayErrorMessage">
				{{ getLabel('template.validation.message2') }}
			</div>
		</mat-card-content>
	</mat-card>
</app-page>
