import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoaderService} from '../../../../services/loader.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
	selector: 'app-logout-dispatcher',
	templateUrl: './logout-dispatcher.component.html',
	styleUrls: ['./logout-dispatcher.component.scss']
})
export class LogoutDispatcherComponent implements OnInit {

	constructor(private router: Router, private loaderService: LoaderService) {
	}

	ngOnInit(): void {
		this.loaderService.displayLoader();

		this.router.navigateByUrl('/login/idp').then(() => {
			this.loaderService.hideLoader();
		});
	}

}
