import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../../../../model/client.model';
import {NotificationsService} from 'angular2-notifications';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ClientService} from '../../../../services/client.service';
import {DeleteClientComponent} from '../../../dialogs/admin/delete-client/delete-client.component';
import {FormError} from '../../../../model/form-error.model';
import {MatSelectChange} from '@angular/material/select';
import {FileUploadControl} from '@iplab/ngx-file-upload';
import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
	selector: 'app-clients-edit-admin',
	templateUrl: './clients-edit-admin.component.html',
	styleUrls: ['./clients-edit-admin.component.scss']
})
export class ClientsEditAdminComponent implements OnInit, AfterViewInit, OnDestroy {

	client: Client;
	error: FormError;

	fileUpload = new FileUploadControl(null);
	subscription: Subscription;
	uploadedFile: BehaviorSubject<string> = new BehaviorSubject(null);
	headerTextColor: any;

	constructor(
		private clientService: ClientService,
		private notificationsService: NotificationsService,
		private route: ActivatedRoute,
		private router: Router,
		private deleteDialog: MatDialog
	) {
	}

	ngOnInit(): void {
		this.init();
	}

	init(): void {
		const id: number = parseInt(this.route.snapshot.paramMap.get('id'), 10);
		if (id === 0) {
			this.client = new Client();
		} else {
			this.clientService.getClient(id).subscribe(res => {
				this.client = res;
				this.uploadedFile.next(this.client.logo);
			});
		}

		this.error = new FormError(['name', 'clientId', 'clientSecret']);

		this.fileUpload.acceptFiles('image/*').multiple(false).discardInvalid(true);

		this.subscription = this.fileUpload.valueChanges.subscribe(files => {
			this.readImage(files[0]);
		});
	}

	ngAfterViewInit(): void {
		// const fileInput = document.querySelector('[type=file]');
		// fileInput.removeAttribute('multiple');
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	readImage(file: File): void {
		if (file) {
			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				const res = e.target.result as string;

				this.client.logoBase64 = res;
				this.uploadedFile.next(res);
			};
			fileReader.readAsDataURL(file);
		} else {
			this.uploadedFile.next(null);
		}
	}

	goBack(): void {
		this.router.navigateByUrl('/admin/clients').then(() => {
		});
	}

	save(): void {
		this.clientService.save(this.client).subscribe(
			res => {
				this.notificationsService.success('Save client', 'Client saved successfully.');
				setTimeout(() => {
					this.router.navigateByUrl('/admin/clients/' + res).then(() => {
						this.init();
					});
				}, 1000);
			},
			error => {
				this.error.setFields(error.errorFields, error.message);
				this.notificationsService.error('Save client', 'Unable to save client.');
			});
	}

	delete(): void {
		const dialog = this.deleteDialog.open(
			DeleteClientComponent, {
				width: '500px'
			}
		);

		dialog.afterClosed().subscribe(res => {
			if (res && res === true) {
				this.clientService.delete(this.client.id).subscribe(
					deleteResult => {
						if (deleteResult) {
							this.notificationsService.success('Delete client', 'Client removed successfully.');

							setTimeout(() => {
								this.goBack();
							}, 1000);

						} else {
							this.notificationsService.error('Delete client', 'Unable to remove client.');
						}
					},
					error => {
						this.error.setFields(error.errorFields, error.message);
						this.notificationsService.error('Delete client', 'Unable to remove client.');
					});
			}
		});
	}

	isDisabled(): boolean {
		return this.client.clientId === 'idp';
	}

	setClientSecret(clientSecret): void {
		this.client.clientSecret = clientSecret;
		this.error.reset('clientSecret');
	}

	refreshClientCredentials(): void {
		this.clientService.getNewClientCredentials().subscribe(res => {
			this.client.clientId = res.id;
			this.client.clientSecret = res.secret;

			this.error.reset('clientId');
			this.error.reset('clientSecret');
		});
	}

	updateCallbacks(event: MatSelectChange): void {
		if (event.value === true) {
			if (!this.client.loginCallbackUrl || this.client.loginCallbackUrl.trim() === '') {
				this.client.loginCallbackUrl = 'https://[SCP-URL]/#/idp/login-callback';
			}
			if (!this.client.logoutCallbackUrl || this.client.logoutCallbackUrl.trim() === '') {
				this.client.logoutCallbackUrl = 'https://[SCP-URL]/#/idp/logout-callback';
			}
			if (!this.client.profileCallbackUrl || this.client.profileCallbackUrl.trim() === '') {
				this.client.profileCallbackUrl = 'https://[SCP-URL]/#/idp/profile-callback';
			}
		} else {
			if (this.client.loginCallbackUrl === 'https://[SCP-URL]/#/idp/login-callback') {
				this.client.loginCallbackUrl = '';
			}
			if (this.client.logoutCallbackUrl === 'https://[SCP-URL]/#/idp/logout-callback') {
				this.client.logoutCallbackUrl = '';
			}
			if (this.client.profileCallbackUrl === 'https://[SCP-URL]/#/idp/profile-callback') {
				this.client.profileCallbackUrl = '';
			}
		}
	}

	deleteLogo(): void {
		this.clientService.deleteLogo(this.client.clientId).subscribe(res => {
			this.fileUpload.clear();
			this.client.logoBase64 = '';
			this.client.logo = '';
		});
	}

	getLogoPlaceholder(): void {
		this.uploadedFile.next('assets/logo/no-image.png');
	}
}
