<app-page [displayMenuIcon]="false" [logo]="clientUI.logo" [headerBackgroundColor]="clientUI.backgroundColor" [headerTextColor]="clientUI.textColor" *ngIf="clientUI">
  <mat-card>
    <mat-card-title class="form">
		 {{ getLabel('template.title') }}
    </mat-card-title>
    <mat-card-content>
      <div class="message">{{ getLabel('template.message') }}</div>
    </mat-card-content>
  </mat-card>
</app-page>
