import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {ActivatedRoute} from '@angular/router';

@Component({
	selector: 'app-validate-email',
	templateUrl: './validate-email.component.html',
	styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit {

	displayOkMessage: boolean;
	displayErrorMessage: boolean;

	public language: string;
	public labels = {
		'IT': {
			'template.validation': 'VERIFICA DELL\'EMAIL',
			'template.validation.message1': 'L\e-mail è stata correttamente verificata. E\' adesso possibile chiudere questa finestra.',
			'template.validation.message2': 'La verifica dell\'e-mail non è riuscita; è possibile che questa azione sia già stata eseguita in precedenza; per sicurezza, provare ad accedere all\'applicazione di riferimento in cui ci si è registrati. Se anche il login non riesce, contattare l\'amministratore dell\'applicazione.'
		},
		'EN': {
			'template.validation': 'EMAIL VALIDATION',
			'template.validation.message1': 'Your e-mail was successfully validated. You can close this window.',
			'template.validation.message2': 'Email validation has failed; it may be that this action has been done before; to be safe, try logging into the referenced application where you registered. In case the login also fails, kindly contact the application administrator.'
		}
	};

	constructor(
		private route: ActivatedRoute,
		private userService: UserService,
		private loaderService: LoaderService
	) {
	}

	ngOnInit(): void {
		this.loaderService.displayLoader();

		this.language = this.route.snapshot.data.language;

		const validationToken: string = this.route.snapshot.paramMap.get('validationToken');
		this.displayOkMessage = false;
		this.displayErrorMessage = false;

		this.userService.validateEmail(validationToken).subscribe(res => {
			if (res) {
				this.displayOkMessage = true;
			} else {
				this.displayErrorMessage = true;
			}

			this.loaderService.hideLoader();
		});
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}

}
