import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {LoaderService} from '../../../../services/loader.service';
import {UserService} from '../../../../services/user.service';

@Component({
	selector: 'app-login-dispatcher',
	templateUrl: './login-dispatcher.component.html',
	styleUrls: ['./login-dispatcher.component.scss']
})
export class LoginDispatcherComponent implements OnInit {


	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private userService: UserService,
		private loaderService: LoaderService) {
	}

	ngOnInit(): void {
		this.loaderService.displayLoader();

		const token: string = this.route.snapshot.queryParamMap.get('token');
		if (token) {
			const loginResponse = this.authService.decodeJwtToken(token);
			if (loginResponse) {
				const user = AuthService.getUser();
				let url: string;

				if (user.role === 'Developer') {
					url = '/admin/home?from=login';
				} else {
					url = '/admin/analytics?from=login';
				}

				this.router.navigateByUrl(url).then(() => {
					this.loaderService.hideLoader();
				});
			}
		}
	}

}
