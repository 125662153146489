import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientService} from '../../../../services/client.service';
import {NotificationsService} from 'angular2-notifications';
import {Client} from '../../../../model/client.model';
import {AuthService} from '../../../../services/auth.service';
import {forkJoin} from 'rxjs';
import {LoaderService} from '../../../../services/loader.service';
import {UserService} from '../../../../services/user.service';
import {ForgotPasswordComponent} from '../../../dialogs/hmi/forgot-password/forgot-password.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	public email: string;
	public password: string;

	public clientUI: any;

	public language: string;
	public labels = {
		'IT': {
			'template.signIn': 'Accedi',
			'template.forgotPassword': 'Ho dimenticato la password',
			'notification.loginError.title': 'Errore di login',
			'notification.loginError.message1': 'Token non valido.',
			'notification.loginError.message2': 'Le credenziali fornite non sono valide.',
			'notification.invalidClient.title': 'Client non valido',
			'notification.invalidClient.message': 'Client sconosciuto',
			'notification.forgotPassword.title': 'Password dimenticata',
			'notification.forgotPassword.message1': 'Le istruzioni per cambiare la password sono state inviate alla tua e-mail.',
			'notification.forgotPassword.message2': 'Non è stato possibile inviare le istruzioni del cambio password alla tua e-mail.'
		},
		'EN': {
			'template.signIn': 'Sign in',
			'template.forgotPassword': 'Forgot password',
			'notification.loginError.title': 'Login error',
			'notification.loginError.message1': 'Invalid token.',
			'notification.loginError.message2': 'The supplied credential is invalid.',
			'notification.invalidClient.title': 'Invalid client',
			'notification.invalidClient.message': 'Unknown client',
			'notification.forgotPassword.title': 'Forgot password',
			'notification.forgotPassword.message1': 'Instructions on how to reset your password were sent to your e-mail.',
			'notification.forgotPassword.message2': 'Unable to sent the instructions to your e-mail.'
		}
	};

	constructor(
		private route: ActivatedRoute,
		private clientService: ClientService,
		private authService: AuthService,
		private userService: UserService,
		private loaderService: LoaderService,
		private notificationsService: NotificationsService,
		private forgotPasswordDialog: MatDialog) {
	}

	ngOnInit(): void {
		this.email = '';
		this.password = '';

		this.loaderService.displayLoader();

		this.language = this.route.snapshot.data.language;

		const clientId: string = this.route.snapshot.paramMap.get('clientId');
		const token: string = AuthService.getToken();
		if (token) {
			const verifyObservable = this.authService.verifyToken();
			const clientObservable = this.clientService.getClientByClientId(clientId);

			let validationResult: boolean;
			let client: Client;

			forkJoin([verifyObservable, clientObservable]).subscribe(
				res => {
					validationResult = res[0];
					client = res[1];

					this.clientUI = ClientService.getClientUI(client);

					if (validationResult) {
						this.authService.loginWithToken(clientId, false).subscribe(
							loginResponse => {
								const jwt = loginResponse.token;
								window.location.href = this.buildUrl(client.loginCallbackUrl, jwt);
							},
							error => {
								this.notificationsService.error(this.getLabel('notification.loginError.title'), this.getLabel('notification.loginError.message1'));
							});
					} else {
						this.loaderService.hideLoader();
					}
				},
				error => {
					this.loaderService.hideLoader();

					if (!client) {
						this.notificationsService.error(
							this.getLabel('notification.invalidClient.title'),
							this.getLabel('notification.invalidClient.message') + ' ' + clientId + '.');
					}
				});
		} else {
			this.clientService.getClientByClientId(clientId).subscribe(client => {
				this.clientUI = ClientService.getClientUI(client);
				this.loaderService.hideLoader();
			}, error => {
				this.loaderService.hideLoader();
			});
		}
	}

	login(event: Event): void {
		event.preventDefault();

		this.loaderService.displayLoader();

		const clientId: string = this.route.snapshot.paramMap.get('clientId');
		this.clientService.getClientByClientId(clientId).subscribe(
			client => {
				this.authService.loginWithCredentials(this.email, this.password, clientId, false).subscribe(
					loginResponse => {
						const jwt = loginResponse.token;
						window.location.href = this.buildUrl(client.loginCallbackUrl, jwt);
					},
					error => {
						this.loaderService.hideLoader();
						this.notificationsService.error(this.getLabel('notification.loginError.title'), this.getLabel('notification.loginError.message2'));
					});
			},
			error => {
				this.loaderService.hideLoader();
				this.notificationsService.error(this.getLabel('notification.invalidClient.title'), this.getLabel('notification.invalidClient.message') + ' ' + clientId + '.');
			});
	}

	forgotPassword(): void {
		const dialog = this.forgotPasswordDialog.open(
			ForgotPasswordComponent, {
				width: '500px',
				data: {
					clientUI: this.clientUI,
					language: this.language
				}
			}
		);

		dialog.afterClosed().subscribe(email => {
			if (email && email.trim() !== '') {
				this.loaderService.displayLoader();
				this.userService.sendResetPasswordEmail(email).subscribe(
					res => {
						this.loaderService.hideLoader();
						this.notificationsService.success(this.getLabel('notification.forgotPassword.title'), this.getLabel('notification.forgotPassword.message1'));
					},
					error => {
						this.loaderService.hideLoader();
						this.notificationsService.error(this.getLabel('notification.forgotPassword.title'), this.getLabel('notification.forgotPassword.message2'));
					}
				);
			}
		});
	}

	setPassword(password): void {
		this.password = password;
	}

	getLabel(key: string): string {
		return this.labels[this.language][key];
	}

	private buildUrl(url: string, token: string): string {
		let prefix = '?';
		if (url.indexOf('?') > 0) {
			prefix = '&';
		}

		return url + prefix + 'token=' + token;
	}
}
